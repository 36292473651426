
import {BaseModel} from './base.model'

export class Association extends BaseModel{



 id: Number
ass_code: String   
ass_name: String
ass_line1: String 
ass_line2: String
ass_line3: String
ass_country: String
ass_city: String
ass_county: String
ass_state: String
ass_zip: Number
ass_phone: String
ass_fax: String
ass_mail: String
ass_contact_fname: String
ass_contact_lname: String
ass_contact_adress: String
ass_contact_tel: String


}