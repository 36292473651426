<!--begin::Notifications-->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="dropdown dropdown-fluid">
	<!--begin::Toggle-->
	<div ngbDropdownToggle class="topbar-item">
		<ng-container *ngIf="useSVG">
			<div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
				<span  class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="icon"></span>
				<!-- <span [className]="notificationExist ? 'pulse-ring' : '' "></span> -->
				
					<span  [ngClass]="notificationExist ? 'pulse-ring' : ''  "></span>
				
				<!-- <span *ngIf="notificationExist" class=""></span> -->
			</div>
		</ng-container>
		<ng-container *ngIf="!useSVG">
			<i [ngClass]="icon"></i>
		</ng-container>
	</div>
	<!--end::Toggle-->

	<div>
		{{ time | async }}
	</div>

	<!--begin::Dropdown-->
	<div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg show">
		<form>
			<!--begin::Header-->
			<div class="d-flex flex-column pt-10 bp-10 bgi-size-cover bgi-no-repeat rounded-top"
				[ngStyle]="{'background-image': backGroundStyle() }">
				<!--begin::Title-->
				<h4 class="d-flex flex-center rounded-top">
					<span class="text-white">Notifications</span>
					<span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{notifications_nb}} nouvelle</span>
				</h4>
				<!--end::Title-->
				<!--begin::Tabs-->
				<ul ktTabClickEvent
					class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-2 px-6"
					role="tablist">
					<li class="nav-item">
						<a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab"
							href="javascript:;" role="tab" aria-selected="true">Bon de commande</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-2')" class="nav-link" data-toggle="tab" href="javascript:;"
							role="tab" aria-selected="false">Commande</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;"
							role="tab" aria-selected="false">Logs</a>
					</li>
				</ul>
				<!--end::Tabs-->
			</div>
			<!--end::Header-->

			<ngb-tabset #tab="ngbTabset" class="tab-content">
				<ngb-tab id="tab-id-1" class="tab-pane">
					<ng-template ngbTabContent>
						<div class="p-8">
							<div class="pr-7 mr-n7 scroll" [perfectScrollbar]="{wheelPropagation: false}"
								[ngStyle]="{'max-height': '300px', 'position': 'relative'}">
								<!--begin::Nav-->
								<div *ngFor="let notif of notifications" class="d-flex align-items-center mb-6">
									<div class="symbol symbol-40 symbol-light-danger mr-5">
										<span class="symbol-label">
											<span [inlineSVG]="'./assets/media/svg/icons/Communication/Clipboard-list.svg'"
												class="svg-icon svg-icon-lg svg-icon-danger"></span>
										</span>
									</div>
									<div class="d-flex flex-column font-weight-bold">
										<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
											{{notif.po_nbr}}
										</a>
										<span class="text-muted">
											{{notif.po_vend }} -- {{notif.po_ord_date}}
										</span>
									</div>
								</div>
								
								<!--end::Nav-->
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-2">
					<ng-template ngbTabContent>
						<div [perfectScrollbar]="{wheelPropagation: false}" class="navi navi-hover scroll mb-3 mt-3 ps"
							[ngStyle]="{'height': '300px', 'position': 'relative'}">
							<!--begin::Nav-->


							<a *ngFor="let order of orders" href="#" class="navi-item">
								<div class="navi-link">
									<div class="navi-icon mr-2">
										<i class="flaticon2-notification text-primary"></i>
									</div>
									<div class="navi-text">
										<div class="font-weight-bold">
											{{order.order_code}}
										</div>
										<div class="text-muted">{{order.customer }} -- {{order.order_emp}}</div>
									</div>
								</div>
							</a>

							
							
							<!--end::Nav-->
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-3">
					<ng-template ngbTabContent>
						<div class="d-flex flex-center text-center text-muted" style="min-height: 200px;">
							All caught up!
							<br />No new notifications.
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</form>
	</div>
	<!--end::Dropdown-->
</div>
<!--end::Notifications-->