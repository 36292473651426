

<kt-portlet class="main-container">
  
  <kt-portlet-header>

  </kt-portlet-header>
  <kt-portlet-body class="main-container">
    <div class="example-preview">
      <div>
        {{ time | async }}
      </div>
            
           
            
      <div>
          <full-calendar [options]="calendarOptions"></full-calendar>
      </div>
  </div>  
  </kt-portlet-body>
</kt-portlet>