export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
   //Palmary  apiUrl: "http://10.10.15.90:3001/api/v1",
      // apiUrl: "http://localhost:3001/api/v1",
  //  apiUrl: "http://192.168.1.252:3001/api/v1",
  //  apiUrl: "http://10.10.130.9:3001/api/v1",
      apiUrl: "http://38.242.222.65:7909/api/v1",
  Instance: "Prod",
  App: "DD", /* RH,DD,ERP*/
};
