<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0"  style="background-color: #ffffff">
  <!--begin::Signin-->
  <div class="login-form login-signin" >

    

    <div class="text-center mb-10 mb-lg-20">
     
      <h3 class="font-size-h1">Patient Sign In</h3>
      <p class="text-muted font-weight-bold">
        Entrez votre numéro de téléphone et votre mot de passe
      </p>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <!--begin::Form-->
    <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate" id="kt_login_form">
      <div class="form-group">

         <div class="form-group col-12">
         

        <div class="alert alert-warning" role="alert" *ngIf="isExist" (click)="goToSignupPage()">
          Aucun patient avec ce numéro de téléphone n'existe, souhaitez-vous accéder à la page d'inscription ? 
        </div>
      </div> 
      <!-- (close)="onAlertClose($event)" -->

        <mat-form-field>
          <mat-label>Numéro de téléphone:</mat-label>
          <input matInput type="text" placeholder="Numéro de téléphone:" formControlName="phone" (change)="onChangePhone()"
            autocomplete="off" />
          <mat-error *ngIf="isControlHasError('phone', 'required')">
            <strong>Ce Champs est obligatoire</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('phone', 'pattern')">
            <strong>wrong pattern</strong>
          </mat-error>
        </mat-form-field>

      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Mot de passe:</mat-label>
          <input matInput type="password" placeholder="Mot de passe:"
            formControlName="password" autocomplete="off" />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
        
        <button id="kt_login_signin_submit" (click)="submit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold px-9 py-4 my-3">Login</button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>