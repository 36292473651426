import { BaseModel } from "./base.model";

export class InvoiceOrderTemp extends BaseModel {
  id: Number;
  ith_nbr: String;
  ith_cust: String;
  ith_ship: String;
  ith_category: String;
  ith_ord_date: String;
  ith_req_date: String;
  ith_due_date: String;
  ith_rmks: String;
  ith_cr_terms: String;
  ith_fob: String;
  ith_po: String;
  ith_shipvia: String;
  ith_partial: Boolean;
  ith_print_so: Boolean;
  ith_inv_nbr: String;
  ith_pr_list: String;
  ith_xslspsn: String;
  ith_source: String;
  ith_xcomm_pct: Number;
  ith_cr_card: String;
  ith_print_pl: Boolean;
  ith_cr_init: String;
  ith_stat: String;
  ith__qad01: Number;
  ith__qad02: Number;
  ith__qad03: Number;
  ith_disc_pct: Number;
  ith_tax_pct: Number;
  ith_prepaid: Number;
  ith_to_inv: Boolean;
  ith_invoiced: Boolean;
  ith_ar_acct: String;
  ith_ar_cc: String;
  ith_inv_date: String;
  ith_ship_date: String;
  ith_taxable: Boolean;
  ith_cmtindx: Number;
  ith__qad04: Number;
  ith_user1: String;
  ith_user2: String;
  ith_curr: String;
  ith_ex_rate: Number;
  ith_lang: String;
  ith_type: String;
  ith_conf_date: String;
  ith_rev: Number;
  ith_bol: String;
  ith__qad05: Number;
  ith_pst: Boolean;
  ith_fst_id: String;
  ith_amt: Number;
  ith_tot_amt: Number;
  ith_tax_amt: Number;
  ith_trl1_amt: Number;
  ith_trl1_cd: String;
  ith_trl2_amt: Number;
  ith_trl2_cd: String;
  ith_trl3_amt: Number;
  ith_trl3_cd: String;
  ith_weight: Number;
  ith_weight_um: String;
  ith_size: Number;
  ith_size_um: String;
  ith_cartons: Number;
  ith_site: String;
  ith_pst_id: String;
  ith_cncl_date: String;
  ith_quote: String;
  ith_taxc: String;
  ith__chr01: String;
  ith__chr02: String;
  ith__chr03: String;
  ith__chr04: String;
  ith__chr05: String;
  ith__chr06: String;
  ith__chr07: String;
  ith__chr08: String;
  ith__chr09: String;
  ith__chr10: String;
  ith__dte01: String;
  ith__dte02: String;
  ith__dec01: Number;
  ith__dec02: Number;
  ith__log01: Boolean;
  ith_credit: Boolean;
  ith_inv_cr: String;
  ith_project: String;
  ith_channel: String;
  ith_pst_pct: Number;
  ith_fr_list: String;
  ith_fr_terms: String;
  ith_slspsn: String;
  ith_comm_pct: Number;
  ith_inv_mthd: String;
  ith_fix_rate: Boolean;
  ith_ent_ex: Number;
  ith_bill: String;
  ith_print_bl: Boolean;
  ith_userid: String;
  ith_tax_date: String;
  ith_fsm_type: String;
  ith_conrep: String;
  ith_bank: String;
  ith_tax_env: String;
  ith_sched: Boolean;
  ith_fr_min_wt: Number;
  ith_pr_list2: String;
  ith_tax_usage: String;
  ith_sa_nbr: String;
  ith_fix_pr: Boolean;
  ith_sch_mthd: String;
  ith_pricing_dt: String;
  ith_priced_dt: String;
  ith_ca_nbr: String;
  ith_eng_code: String;
  ith_fcg_code: String;
  ith_ship_eng: Boolean;
  ith_crprlist: String;
  ith__qadc01: String;
  ith__qadc02: String;
  ith__qadc03: String;
  ith__qadc04: String;
  ith__qadc05: String;
  ith__qadl01: Boolean;
  ith__qadl02: Boolean;
  ith_incl_iss: Boolean;
  ith__qadi01: Number;
  ith__qadi02: Number;
  ith__qadi03: Number;
  ith__qadd01: Number;
  ith__qadd02: Number;
  ith__qadd03: Number;
  ith__qadt01: String;
  ith__qadt02: String;
  ith__qadt03: String;
  ith_auth_days: Number;
  ith_cum_acct: Boolean;
  ith_merge_rss: Boolean;
  ith_ship_cmplt: Number;
  ith_bump_all: Boolean;
  ith_primary: Boolean;
  ith_cust_po: String;
  ith_secondary: Boolean;
  ith_ship_po: String;
  ith_ex_rate2: Number;
  ith_ex_ratetype: String;
  ith_div: String;
  ith_exru_seq: Number;
  ith_app_owner: String;
  ith_ar_sub: String;
  ith_seq_order: Boolean;
  ith_inc_in_rss: Boolean;
  ith_firm_seq_days: Number;
  ith_prep_tax: Number;
  ith__qadl04: Boolean;
  ith_custref_val: Boolean;
  ith_consignment: Boolean;
  ith_max_aging_days: Number;
  ith_consign_loc: String;
  ith_intrans_loc: String;
  ith_auto_replenish: Boolean;
  ith_revenue: String;
  ith_fsaccr_acct: String;
  ith_fsaccr_sub: String;
  ith_fsaccr_cc: String;
  ith_fsdef_acct: String;
  ith_fsdef_sub: String;
  ith_fsdef_cc: String;
  ith_manual_fr_terms: Boolean;
  ith_domain: String;
  oid_ith_mstr: Number;
  ith_req_time: String;
  ith_disc_glb: Number;
  ith_transport: Number;
  ith_rt_gara: Number;
}
