
import {BaseModel} from './base.model'

export class AffectEmp extends BaseModel{



 id: Number
pme_pm_code: String
pme_site : String   
pme_inst: String
pme_task: String
pme_task_status: String
pme_start_date: String
pme_end_date: String
pme_start_time: String
pme_end_time: String
pme_internal: Boolean
pme_employe: String
pme_vend: String

pme_domain:  String

}