import {BaseModel} from './base.model'

export class BomPart extends BaseModel{



    id: Number

    ptb_part: String 

    ptb_bom: String 

}