<kt-portlet>
    <kt-portlet-header [sticky]="true" [title]="'La liste Répertoire des Fournisseurs'" [class]="'card-head-lg'">
       
    </kt-portlet-header>
    <kt-portlet-body>
        <div class="example-preview">
            <div class="row">
                <angular-slickgrid class="col-12"  gridId="gridMesure" [columnDefinitions]="columnDefinitions"
                (onAngularGridCreated)="angularGridReady($event)"
                    [gridOptions]="gridOptions" [dataset]="dataset">
                </angular-slickgrid>
            </div>
            
        </div>

    </kt-portlet-body>
</kt-portlet>
