import { NumberToLetter } from "./numberToString";
import { round } from "lodash";
import { replace } from "lodash";


const svg = `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1327.000000pt" height="311.000000pt" viewBox="0 0 1327.000000 311.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,311.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M4163 3697 c-47 -23 -54 -36 -23 -44 19 -5 20 -14 20 -204 0 -174 -2
-199 -16 -199 -9 0 -14 -7 -12 -17 2 -14 15 -19 57 -21 64 -4 88 12 54 35 -23
15 -23 18 -23 239 0 122 -3 225 -7 227 -5 3 -27 -5 -50 -16z"/>
<path d="M4768 3698 c-41 -24 -50 -48 -18 -48 19 0 20 -7 20 -194 0 -188 -1
-194 -22 -208 -34 -23 -13 -38 53 -38 52 0 59 2 59 20 0 11 -7 20 -15 20 -13
0 -15 29 -15 198 0 190 -6 272 -19 272 -3 0 -23 -10 -43 -22z"/>
<path d="M6112 3708 c-26 -26 -8 -83 24 -76 23 4 31 52 13 74 -14 17 -21 18
-37 2z"/>
<path d="M8308 3699 c-46 -24 -56 -39 -29 -46 20 -5 21 -11 21 -204 l0 -199
-29 0 c-17 0 -33 6 -38 12 -4 7 -39 107 -78 222 -38 115 -75 211 -80 213 -6 2
-44 -94 -84 -213 -48 -141 -81 -220 -93 -227 -10 -7 -18 -20 -18 -29 0 -15 9
-18 54 -18 63 0 79 15 45 40 -23 18 -23 19 -8 67 l15 48 67 3 66 3 16 -37 c20
-48 20 -84 -1 -84 -9 0 -14 -7 -12 -17 3 -16 19 -18 132 -21 138 -3 171 6 129
35 -23 15 -23 18 -23 239 0 122 -3 225 -7 227 -5 3 -25 -3 -45 -14z m-220
-220 c12 -31 18 -60 15 -63 -11 -11 -93 -6 -93 5 0 25 43 130 50 122 4 -4 17
-33 28 -64z"/>
<path d="M8472 3708 c-28 -28 -2 -88 33 -74 27 10 14 86 -15 86 -3 0 -11 -5
-18 -12z"/>
<path d="M9980 3705 c-6 -8 -10 -26 -8 -42 4 -35 43 -45 53 -14 13 43 -21 85
-45 56z"/>
<path d="M3447 3679 c-52 -40 -60 -138 -16 -193 12 -15 52 -52 90 -82 61 -48
69 -59 69 -89 0 -37 -28 -65 -66 -65 -34 0 -79 44 -93 90 -18 60 -31 39 -31
-51 0 -76 5 -90 25 -70 6 6 28 4 57 -6 56 -18 87 -12 129 25 38 34 52 94 34
146 -16 47 -29 61 -95 109 -68 49 -89 75 -90 108 0 34 17 49 56 49 38 0 78
-41 89 -90 13 -57 25 -30 25 55 0 83 -5 96 -27 74 -8 -8 -17 -8 -31 0 -34 18
-97 13 -125 -10z"/>
<path d="M6839 3677 c-77 -52 -124 -186 -100 -291 16 -71 62 -139 109 -162 48
-23 135 -23 185 1 l37 17 0 94 c0 83 2 95 20 106 41 26 23 38 -55 38 -41 0
-75 -3 -75 -6 0 -4 11 -17 25 -30 22 -21 25 -31 25 -98 0 -84 -9 -96 -72 -96
-46 0 -76 24 -104 84 -47 100 -26 251 41 304 54 42 129 15 161 -57 27 -63 38
-51 30 36 -6 81 -12 96 -25 74 -7 -11 -15 -11 -41 -2 -55 19 -123 14 -161 -12z"/>
<path d="M4390 3680 c0 -6 11 -15 25 -20 l25 -10 0 -195 0 -195 -26 -10 c-14
-6 -24 -16 -21 -23 3 -9 42 -12 147 -12 l143 0 14 59 c17 77 12 91 -17 43 -29
-48 -46 -57 -114 -57 l-56 0 0 90 0 90 44 0 c46 0 76 -18 76 -46 0 -8 5 -14
10 -14 6 0 10 35 10 85 0 50 -4 85 -10 85 -5 0 -10 -8 -10 -18 0 -30 -21 -42
-72 -42 l-48 0 0 80 0 80 58 0 c60 0 81 -11 95 -52 4 -10 11 -18 17 -18 11 0
13 32 4 79 l-6 31 -144 0 c-92 0 -144 -4 -144 -10z"/>
<path d="M5000 3675 c0 -8 9 -15 20 -15 28 0 31 -22 28 -225 -3 -179 -3 -180
-26 -183 -15 -2 -22 -9 -20 -20 3 -14 16 -17 78 -17 63 0 75 3 78 18 2 10 -3
17 -13 17 -22 0 -35 41 -35 116 l0 64 80 0 81 0 -3 -87 c-3 -83 -4 -88 -26
-91 -15 -2 -22 -9 -20 -20 5 -25 151 -25 156 -1 2 10 -5 19 -18 22 -19 5 -20
12 -20 202 0 188 1 197 20 202 11 3 20 12 20 19 0 11 -19 14 -80 14 -64 0 -80
-3 -80 -15 0 -8 9 -15 20 -15 25 0 32 -27 28 -110 l-3 -65 -54 -3 c-94 -5
-101 -1 -101 68 0 68 13 110 35 110 8 0 15 7 15 15 0 12 -16 15 -80 15 -64 0
-80 -3 -80 -15z"/>
<path d="M9583 3624 c-6 -17 -25 -47 -43 -67 -31 -36 -33 -57 -4 -57 11 0 14
-22 14 -117 0 -137 10 -168 55 -168 22 0 33 8 48 33 21 35 17 53 -7 33 -29
-24 -36 -1 -36 110 l0 109 25 0 c20 0 25 5 25 25 0 20 -5 25 -24 25 -22 0 -25
6 -33 53 l-9 52 -11 -31z"/>
<path d="M3725 3525 c-29 -28 -33 -76 -9 -96 14 -11 18 -10 30 6 8 10 14 29
14 42 0 34 29 41 52 14 28 -35 22 -48 -29 -74 -85 -42 -117 -124 -72 -181 25
-32 68 -35 95 -5 l18 20 8 -20 c15 -42 88 -9 88 40 0 16 -4 19 -20 14 -19 -6
-20 -2 -20 103 0 95 -3 113 -21 136 -29 36 -99 37 -134 1z m100 -178 c0 -48
-17 -77 -46 -77 -42 0 -35 81 9 104 37 21 37 21 37 -27z"/>
<path d="M3953 3530 c-38 -23 -42 -35 -13 -43 18 -5 20 -14 20 -115 0 -101 -2
-111 -22 -124 -34 -23 -13 -38 52 -38 65 0 86 15 52 38 -20 13 -22 23 -22 106
0 101 15 141 43 118 32 -27 59 14 41 62 -8 20 -48 21 -64 1 -7 -8 -16 -15 -21
-15 -5 0 -9 7 -9 15 0 20 -19 19 -57 -5z"/>
<path d="M5435 3525 c-29 -28 -33 -76 -9 -96 14 -11 18 -10 30 6 8 10 14 29
14 42 0 48 49 38 64 -14 6 -18 0 -25 -41 -45 -85 -43 -117 -125 -72 -182 24
-31 57 -33 92 -6 25 20 25 20 30 0 7 -27 41 -26 67 2 27 29 27 62 0 53 -19 -6
-20 -2 -20 103 0 95 -3 113 -21 136 -29 36 -99 37 -134 1z m102 -179 c3 -78
-69 -110 -75 -34 -3 33 1 40 30 58 18 11 35 20 38 20 3 0 6 -20 7 -44z"/>
<path d="M5663 3530 c-38 -23 -42 -35 -13 -43 18 -5 20 -14 20 -115 0 -101 -2
-111 -22 -124 -34 -23 -13 -38 52 -38 66 0 81 11 50 37 -18 15 -20 29 -20 114
0 80 3 99 18 112 25 22 49 21 62 -2 5 -11 10 -62 10 -114 0 -87 -2 -96 -22
-110 -36 -24 -11 -39 57 -35 60 3 87 24 46 35 -20 5 -21 12 -21 108 0 86 3
104 18 118 25 22 49 21 62 -2 5 -11 10 -62 10 -114 0 -86 -2 -96 -22 -109 -42
-28 -12 -37 117 -37 115 0 125 2 125 19 0 10 -7 20 -15 24 -12 4 -15 31 -15
151 0 163 -1 165 -70 124 l-35 -22 23 -15 c21 -14 22 -21 22 -127 0 -99 -2
-113 -17 -117 -49 -11 -53 -3 -53 108 0 104 -15 168 -43 186 -24 15 -61 8 -87
-17 l-27 -26 -17 26 c-20 31 -63 33 -99 5 -25 -20 -25 -20 -30 0 -6 25 -23 25
-64 0z"/>
<path d="M6236 3535 c-9 -25 -7 -83 3 -90 5 -3 13 5 17 17 4 13 12 26 18 30
15 10 86 10 86 0 0 -5 -31 -61 -70 -126 -38 -65 -70 -127 -70 -137 0 -18 7
-19 111 -17 l111 3 3 50 c3 64 -5 79 -20 34 -11 -33 -14 -34 -69 -37 -44 -2
-56 0 -50 9 79 128 144 247 142 259 -5 23 -203 28 -212 5z"/>
<path d="M7143 3533 c-33 -17 -45 -43 -19 -43 20 0 26 -32 26 -138 0 -86 -2
-94 -20 -99 -13 -3 -20 -12 -18 -22 2 -12 18 -17 66 -19 53 -3 62 0 62 14 0 9
-7 22 -16 29 -12 8 -18 33 -22 88 -6 106 19 162 56 125 15 -15 42 7 42 36 0
42 -45 62 -70 31 -7 -8 -16 -15 -21 -15 -5 0 -9 7 -9 15 0 19 -16 18 -57 -2z"/>
<path d="M7356 3508 c-41 -47 -51 -87 -42 -161 7 -64 49 -127 90 -138 103 -28
191 128 140 249 -26 62 -61 92 -110 92 -37 0 -47 -5 -78 -42z m104 -20 c27
-29 35 -59 35 -134 0 -47 -5 -67 -19 -83 -48 -53 -100 11 -100 124 -1 107 35
146 84 93z"/>
<path d="M7608 3533 c-23 -25 -31 -67 -24 -108 6 -28 18 -44 57 -72 59 -42 76
-68 58 -89 -23 -28 -62 -13 -84 33 -11 23 -23 42 -27 43 -5 0 -8 -29 -8 -63
l0 -64 33 -2 c66 -6 91 -1 113 20 55 51 36 134 -41 183 -50 31 -68 56 -59 80
14 37 73 10 88 -41 13 -42 26 -24 26 35 l0 58 -58 2 c-45 2 -62 -2 -74 -15z"/>
<path d="M8450 3529 l-35 -22 23 -15 c21 -14 22 -21 22 -128 0 -97 -2 -114
-16 -114 -9 0 -14 -7 -12 -17 2 -14 15 -19 57 -21 64 -4 88 12 54 35 -22 15
-23 21 -23 159 0 162 -1 164 -70 123z"/>
<path d="M8603 3530 c-38 -23 -42 -35 -13 -43 19 -5 20 -14 20 -121 0 -92 -3
-116 -14 -116 -15 0 -28 -24 -19 -34 4 -3 33 -6 65 -6 51 0 58 2 58 20 0 11
-7 20 -15 20 -12 0 -15 18 -15 103 0 88 3 106 18 120 25 22 49 21 62 -2 5 -11
10 -62 10 -114 0 -86 -2 -96 -22 -109 -34 -23 -13 -38 53 -38 52 0 59 2 59 20
0 11 -7 20 -15 20 -12 0 -15 18 -15 103 0 88 3 106 18 120 25 22 49 21 62 -2
5 -11 10 -63 10 -116 0 -89 -2 -97 -20 -102 -13 -3 -20 -12 -18 -22 2 -12 18
-17 66 -19 57 -3 62 -1 62 18 0 11 -7 20 -15 20 -12 0 -15 18 -15 98 0 146
-22 202 -78 202 -14 0 -38 -11 -52 -25 l-27 -26 -17 26 c-20 31 -63 33 -99 5
-25 -20 -25 -20 -30 0 -6 25 -23 25 -64 0z"/>
<path d="M9061 3529 c-35 -33 -51 -81 -51 -154 0 -146 100 -222 175 -133 27
32 39 66 33 95 -3 14 -7 12 -21 -14 -30 -57 -91 -53 -122 7 -34 66 -11 87 85
78 68 -7 77 0 61 52 -7 24 -23 53 -36 66 -32 32 -92 33 -124 3z m65 -27 c6 -4
16 -17 23 -29 11 -22 10 -23 -39 -23 -54 0 -62 10 -33 42 17 19 31 22 49 10z"/>
<path d="M9268 3532 c-29 -18 -37 -42 -14 -42 16 0 26 -51 26 -142 0 -76 -6
-98 -27 -98 -8 0 -13 -7 -11 -17 5 -25 121 -27 126 -2 2 10 -5 19 -18 22 -18
5 -20 13 -20 101 0 83 3 99 20 116 11 11 26 20 33 20 30 0 37 -25 37 -131 0
-100 -1 -107 -21 -112 -43 -11 -14 -32 49 -35 56 -3 62 -1 62 16 0 11 -7 22
-15 26 -12 4 -15 27 -15 113 0 83 -4 116 -18 143 -21 44 -61 52 -103 21 -29
-22 -29 -22 -29 -1 0 24 -24 25 -62 2z"/>
<path d="M9722 3527 c-23 -24 -31 -96 -12 -98 31 -5 35 -1 40 36 6 45 34 60
57 29 24 -33 16 -61 -22 -74 -18 -6 -48 -27 -65 -46 -75 -85 -4 -219 76 -143
l22 21 11 -21 c16 -29 45 -26 70 5 27 34 28 57 2 49 -18 -5 -19 1 -23 111 -3
108 -5 117 -27 135 -34 28 -101 26 -129 -4z m98 -185 c0 -51 -12 -72 -42 -72
-22 0 -41 35 -33 60 6 20 51 60 66 60 5 0 9 -22 9 -48z"/>
<path d="M9963 3533 c-37 -19 -43 -38 -13 -46 18 -5 20 -14 20 -115 0 -101 -2
-111 -22 -124 -34 -23 -13 -38 53 -38 52 0 59 2 59 20 0 11 -7 20 -15 20 -12
0 -15 20 -15 119 0 131 -7 181 -24 180 -6 0 -26 -8 -43 -16z"/>
<path d="M10113 3534 c-33 -17 -45 -44 -19 -44 18 0 26 -45 26 -147 0 -77 -2
-85 -20 -90 -13 -3 -20 -12 -18 -22 5 -23 128 -30 128 -6 0 8 -9 24 -20 35
-16 16 -20 33 -20 95 0 103 23 148 58 113 10 -9 16 -8 29 5 13 13 15 23 7 44
-12 36 -40 41 -70 12 l-24 -22 0 21 c0 26 -13 27 -57 6z"/>
<path d="M10337 3530 c-106 -84 -47 -351 72 -325 36 8 75 59 86 113 8 41 -4
41 -30 -1 -22 -34 -52 -45 -84 -28 -21 12 -58 97 -47 109 4 4 42 7 84 7 66 1
77 4 80 19 6 29 -6 62 -34 95 -32 38 -87 43 -127 11z m77 -42 c24 -34 20 -38
-34 -38 -54 0 -61 9 -30 40 26 26 45 25 64 -2z"/>
<path d="M1490 3269 c-194 -20 -573 -73 -667 -94 l-23 -5 0 -305 0 -305 23 -5
c34 -8 229 -32 457 -57 288 -30 668 -30 940 0 212 24 402 48 438 57 l22 5 0
225 0 225 -22 4 c-13 3 -56 12 -96 21 -39 10 -83 15 -95 11 -17 -4 -30 1 -47
19 -13 14 -28 25 -34 25 -38 0 -159 93 -171 131 -8 24 -18 27 -199 44 -170 16
-387 18 -526 4z m-195 -121 c17 0 41 2 54 4 13 2 26 -3 29 -10 5 -16 -96 -116
-223 -222 l-90 -75 65 -6 c36 -3 90 -9 121 -14 61 -8 119 11 119 40 0 9 4 23
9 33 5 9 15 31 21 49 7 18 20 34 29 37 43 13 89 17 95 8 3 -5 -4 -29 -15 -53
-26 -52 -37 -119 -20 -119 21 0 61 32 61 49 0 22 35 94 52 108 7 6 33 14 57
18 43 7 44 7 37 -19 -3 -14 -5 -26 -2 -26 2 0 24 11 47 25 56 32 84 31 114 -1
l24 -26 30 21 c63 45 131 36 131 -19 0 -16 -9 -53 -20 -81 -12 -28 -18 -54
-15 -57 11 -11 60 26 78 60 29 52 58 79 112 104 56 26 91 30 130 12 19 -9 34
-8 65 2 22 7 43 11 46 8 3 -3 -7 -40 -22 -82 -14 -41 -24 -83 -20 -92 5 -14
10 -13 40 5 70 44 79 8 10 -40 -73 -50 -139 -49 -156 3 -3 8 -15 5 -37 -11
-18 -13 -52 -26 -76 -29 -37 -4 -47 -1 -71 23 l-27 27 -47 -26 c-55 -30 -96
-33 -122 -9 -23 21 -23 50 2 108 24 55 24 55 7 55 -27 0 -76 -58 -88 -104 -14
-55 -23 -63 -77 -72 -39 -6 -42 -5 -42 17 0 13 6 42 14 64 36 107 28 119 -35
56 -30 -30 -39 -46 -39 -74 0 -41 -15 -56 -64 -64 -34 -5 -36 -4 -36 21 0 32
-4 32 -55 1 -52 -32 -88 -32 -113 0 l-20 25 -53 -25 c-64 -29 -116 -31 -200
-8 -58 16 -63 16 -91 0 -65 -37 -76 -13 -23 55 25 34 191 182 274 245 10 7 -9
11 -94 17 -71 6 -94 4 -110 -8 -20 -14 -20 -15 3 -39 l24 -25 -26 -17 c-53
-35 -96 17 -60 73 22 34 39 48 89 73 33 17 51 19 105 13 36 -4 79 -7 95 -6z
m262 -52 c1 -1 0 -12 -3 -23 -7 -26 -54 -44 -94 -36 -33 6 -37 18 -15 50 12
17 22 20 62 15 27 -2 49 -5 50 -6z m-339 -416 c17 -11 32 -23 32 -28 0 -4 25
-7 55 -5 52 2 85 -12 85 -37 0 -19 -105 -29 -153 -16 -22 7 -31 4 -45 -15 -10
-12 -24 -19 -30 -15 -13 8 -5 63 11 84 8 10 7 16 -6 24 -18 10 -14 28 6 28 7
0 27 -9 45 -20z m765 -35 c15 -4 27 -11 27 -15 0 -5 3 -16 6 -25 6 -15 0 -17
-42 -14 -27 1 -57 -1 -67 -6 -12 -5 -24 -2 -34 8 -15 14 -15 18 1 42 10 15 15
33 12 40 -9 24 18 23 44 -1 14 -12 37 -26 53 -29z m137 24 c0 -10 3 -20 8 -20
27 -5 102 -6 125 -2 25 4 27 6 11 17 -13 10 -14 15 -5 21 7 4 18 2 24 -4 6 -6
19 -11 28 -11 19 0 33 -26 18 -36 -5 -3 -7 -14 -4 -25 6 -19 1 -19 -104 -18
-90 1 -113 -2 -127 -15 -22 -22 -39 -15 -37 15 3 37 15 83 25 96 13 17 38 5
38 -18z m-307 -38 c42 -34 12 -41 -206 -43 -98 -1 -171 2 -177 8 -6 6 -5 17 5
31 14 21 22 21 188 20 131 -2 178 -5 190 -16z"/>
<path d="M2248 2951 c-28 -23 -58 -78 -58 -109 0 -22 22 -35 44 -26 27 10 67
84 64 116 -4 39 -17 44 -50 19z"/>
<path d="M2494 3135 c-28 -57 -28 -81 0 -48 9 10 32 37 52 61 l36 42 -30 0
c-27 0 -34 -6 -58 -55z"/>
<path d="M5828 2909 c-42 -22 -48 -38 -18 -46 17 -4 20 -14 20 -55 l0 -50 -29
7 c-47 10 -89 -18 -117 -78 -13 -29 -24 -61 -25 -72 0 -11 -6 -1 -13 22 -27
84 -72 133 -124 133 -9 0 -31 -9 -49 -20 -118 -72 -85 -330 43 -330 61 0 91
31 130 135 6 17 8 14 14 -16 8 -42 44 -105 66 -113 26 -10 69 -7 83 7 11 10
14 10 18 0 6 -18 30 -16 64 4 32 19 38 40 14 49 -13 5 -15 37 -15 203 0 187
-4 242 -19 241 -3 -1 -23 -10 -43 -21z m-262 -230 c17 -34 23 -128 11 -171 -9
-34 -40 -52 -66 -39 -29 16 -46 70 -46 149 0 65 3 76 23 93 29 23 54 13 78
-32z m243 25 c18 -22 21 -41 21 -113 0 -92 -8 -111 -46 -111 -62 0 -88 176
-33 231 25 25 34 24 58 -7z"/>
<path d="M6641 2908 c-11 -30 2 -63 24 -63 28 0 41 29 27 59 -14 32 -40 34
-51 4z"/>
<path d="M8089 2909 c-13 -26 2 -69 25 -69 24 0 40 38 27 66 -13 30 -37 31
-52 3z"/>
<path d="M4900 2895 c0 -9 9 -19 20 -22 27 -7 31 -41 28 -233 -3 -160 -4 -165
-25 -175 -13 -5 -23 -15 -23 -22 0 -9 22 -13 80 -13 64 0 80 3 80 15 0 8 -7
15 -15 15 -24 0 -36 37 -33 108 l3 67 37 -3 c99 -9 163 94 119 192 -29 66 -57
80 -173 84 -87 4 -98 2 -98 -13z m185 -61 c52 -56 22 -160 -43 -152 -26 3 -27
5 -30 77 -2 41 -1 80 2 88 9 22 45 15 71 -13z"/>
<path d="M7420 2895 c0 -9 9 -19 20 -22 27 -7 31 -41 28 -233 -3 -160 -4 -165
-25 -175 -13 -5 -23 -15 -23 -22 0 -20 232 -18 270 2 31 16 52 49 64 98 7 29
-21 108 -43 121 -7 5 -4 19 9 44 24 45 25 80 4 125 -27 57 -63 71 -191 75
-101 4 -113 2 -113 -13z m225 -61 c31 -34 33 -80 3 -111 -17 -18 -32 -23 -70
-23 l-48 0 0 80 0 80 45 0 c37 0 51 -5 70 -26z m12 -219 c42 -48 22 -121 -38
-140 -11 -4 -36 -4 -55 0 l-34 6 0 78 c0 43 3 81 8 85 4 4 27 6 52 3 32 -4 51
-13 67 -32z"/>
<path d="M6496 2835 c-3 -13 -20 -40 -36 -59 -32 -36 -38 -56 -16 -56 19 0 26
-45 26 -159 0 -103 13 -141 50 -141 23 0 60 45 60 73 0 16 -3 17 -15 7 -8 -7
-22 -10 -30 -6 -12 4 -15 26 -15 110 l0 105 28 3 c19 2 28 9 30 26 3 19 -1 22
-27 22 -30 0 -31 1 -31 50 0 53 -14 67 -24 25z"/>
<path d="M5233 2749 c-44 -20 -58 -49 -24 -49 23 0 31 -35 31 -141 0 -68 -3
-81 -20 -91 -39 -25 -24 -38 45 -38 69 0 78 7 45 40 -17 17 -20 33 -20 103 0
60 4 89 16 106 12 16 19 20 27 12 20 -20 46 -12 53 15 5 19 1 31 -14 47 l-22
21 -30 -22 c-29 -22 -30 -22 -30 -2 0 24 -3 24 -57 -1z"/>
<path d="M6295 2761 c-91 -39 -114 -221 -39 -311 51 -60 132 -29 159 63 8 26
11 47 8 47 -3 0 -20 -16 -37 -37 -27 -30 -37 -35 -58 -30 -34 9 -58 61 -58
129 0 60 20 98 51 98 13 0 24 -10 31 -30 19 -56 68 -58 68 -4 0 52 -75 96
-125 75z"/>
<path d="M6638 2749 c-42 -21 -49 -44 -18 -52 18 -5 20 -14 20 -117 0 -103 -2
-112 -20 -117 -11 -3 -20 -12 -20 -19 0 -11 16 -14 65 -14 49 0 65 3 65 14 0
8 -7 16 -15 20 -12 4 -15 27 -15 118 0 115 -8 188 -19 188 -3 -1 -23 -10 -43
-21z"/>
<path d="M6834 2751 c-84 -51 -97 -223 -24 -301 23 -24 36 -30 70 -30 105 0
163 152 105 274 -19 40 -66 76 -100 76 -11 0 -34 -9 -51 -19z m93 -74 c11 -21
17 -56 17 -106 0 -65 -2 -75 -23 -92 -13 -10 -27 -19 -32 -19 -38 0 -76 116
-64 196 12 79 65 90 102 21z"/>
<path d="M7048 2749 c-37 -19 -52 -49 -24 -49 17 0 26 -44 26 -129 0 -80 -8
-111 -26 -111 -8 0 -14 -7 -14 -15 0 -12 14 -15 65 -15 69 0 84 13 45 38 -18
11 -20 23 -20 106 0 76 3 96 18 108 30 26 40 29 56 12 13 -12 16 -38 16 -121
0 -96 -2 -105 -20 -110 -11 -3 -20 -12 -20 -19 0 -11 16 -14 65 -14 49 0 65 3
65 14 0 8 -7 16 -15 20 -12 4 -15 26 -15 111 0 114 -14 166 -48 184 -29 16
-37 14 -71 -15 l-31 -26 0 26 c0 31 -4 31 -52 5z"/>
<path d="M7862 2750 c-116 -71 -82 -330 43 -330 76 0 128 71 129 175 1 93 -56
175 -121 175 -10 0 -33 -9 -51 -20z m94 -71 c17 -34 23 -128 11 -171 -9 -34
-40 -52 -66 -39 -29 16 -46 70 -46 149 0 65 3 76 23 93 29 23 54 13 78 -32z"/>
<path d="M8088 2749 c-37 -19 -52 -49 -24 -49 19 0 26 -32 26 -127 0 -90 -5
-113 -26 -113 -8 0 -14 -7 -14 -15 0 -12 14 -15 65 -15 69 0 78 7 45 40 -18
18 -20 33 -20 160 0 77 -3 140 -7 140 -5 -1 -25 -10 -45 -21z"/>
<path d="M8264 2758 c-47 -22 -60 -115 -21 -159 12 -14 39 -38 60 -53 30 -22
38 -33 35 -54 -5 -41 -49 -43 -77 -4 -12 17 -21 37 -21 46 0 9 -4 16 -10 16
-6 0 -10 -27 -10 -62 l0 -63 55 -3 c67 -4 101 16 114 65 16 58 -6 102 -70 144
-27 17 -51 39 -54 50 -7 21 10 49 29 49 17 0 52 -40 61 -69 13 -43 28 -7 21
51 l-7 53 -42 1 c-23 1 -52 -3 -63 -8z"/>
<path d="M8478 2756 c-13 -6 -29 -29 -37 -51 -19 -55 2 -103 63 -147 50 -35
64 -61 46 -83 -26 -32 -67 -10 -89 48 -18 48 -32 31 -29 -35 l3 -62 61 -4 c58
-3 62 -2 87 28 42 49 38 102 -11 149 -21 20 -51 44 -65 54 -48 32 -26 93 25
66 11 -6 26 -26 34 -45 8 -19 16 -34 19 -34 3 0 5 28 5 63 l0 62 -44 1 c-25 1
-55 -4 -68 -10z"/>
<path d="M8725 2756 c-47 -21 -79 -88 -79 -166 0 -69 11 -98 52 -142 33 -35
87 -38 127 -6 103 81 75 303 -41 323 -16 3 -42 -1 -59 -9z m83 -73 c12 -20 17
-48 17 -105 0 -66 -3 -81 -22 -99 -12 -12 -28 -19 -37 -16 -40 16 -70 120 -56
196 14 76 57 86 98 24z"/>
<path d="M8928 2749 c-42 -21 -49 -44 -18 -52 18 -5 20 -14 20 -117 0 -103 -2
-112 -20 -117 -11 -3 -20 -12 -20 -19 0 -11 16 -14 65 -14 67 0 81 11 48 35
-13 10 -18 33 -21 103 -4 87 -3 92 22 117 50 50 76 3 76 -141 0 -41 -5 -59
-20 -74 -33 -33 -24 -40 45 -40 51 0 65 3 65 15 0 8 -6 15 -14 15 -21 0 -26
23 -26 132 0 159 -39 211 -115 154 l-35 -27 0 25 c0 31 -4 31 -52 5z"/>
<path d="M9234 2758 c-41 -19 -56 -90 -32 -143 6 -14 33 -41 60 -61 41 -29 49
-39 46 -62 -5 -41 -49 -43 -77 -4 -12 17 -21 37 -21 46 0 9 -4 16 -10 16 -6 0
-10 -27 -10 -62 l0 -63 55 -3 c67 -4 101 16 114 65 16 58 -5 100 -70 145 -27
18 -52 41 -55 51 -6 20 11 47 30 47 17 0 52 -40 61 -69 13 -43 28 -7 21 51
l-7 53 -42 1 c-23 1 -52 -3 -63 -8z"/>
<path d="M5917 2753 c-12 -11 -8 -23 7 -23 25 0 31 -22 36 -146 4 -101 8 -126
24 -143 25 -27 64 -27 93 1 l23 21 0 -21 c0 -28 24 -29 68 -2 37 22 41 40 12
47 -19 5 -20 14 -20 139 l0 134 -44 0 c-51 0 -66 -13 -36 -32 18 -12 20 -23
20 -114 0 -87 -3 -103 -19 -118 -28 -25 -55 -17 -62 19 -4 17 -7 78 -8 138
l-1 107 -43 0 c-24 0 -47 -3 -50 -7z"/>
<path d="M2940 1470 c0 -91 3 -110 15 -110 12 0 15 19 15 110 0 91 -3 110 -15
110 -12 0 -15 -19 -15 -110z"/>
<path d="M3280 1545 c0 -35 -1 -36 -34 -30 -28 6 -36 3 -55 -20 -25 -32 -27
-82 -5 -113 12 -18 23 -22 65 -20 l49 2 0 108 c0 65 -4 108 -10 108 -5 0 -10
-16 -10 -35z m-12 -67 c27 -27 4 -98 -32 -98 -30 0 -46 51 -30 94 7 19 45 21
62 4z"/>
<path d="M3870 1470 c0 -90 3 -110 15 -110 10 0 15 16 17 63 3 62 3 62 33 62
30 0 30 0 33 -63 2 -44 7 -62 15 -59 7 2 13 19 15 37 9 85 -24 134 -74 111
-23 -10 -24 -9 -24 29 0 29 -4 40 -15 40 -12 0 -15 -19 -15 -110z"/>
<path d="M5060 1470 c0 -85 3 -111 13 -107 8 2 12 33 12 107 0 74 -4 105 -12
108 -10 3 -13 -23 -13 -108z"/>
<path d="M5428 1569 c-30 -17 -16 -69 18 -69 14 0 44 27 44 40 0 10 -29 40
-38 40 -4 0 -15 -5 -24 -11z m32 -29 c0 -11 -7 -20 -15 -20 -15 0 -21 21 -8
33 12 13 23 7 23 -13z"/>
<path d="M7070 1540 c0 -38 -1 -40 -21 -30 -69 37 -123 -77 -65 -135 21 -21
29 -23 50 -15 14 5 33 7 41 4 13 -5 15 10 15 105 0 67 -4 111 -10 111 -5 0
-10 -18 -10 -40z m-12 -62 c27 -27 4 -98 -32 -98 -30 0 -46 51 -30 94 7 19 45
21 62 4z"/>
<path d="M8450 1541 l0 -39 -31 11 c-28 11 -33 10 -55 -16 -32 -38 -32 -90 0
-122 21 -21 29 -23 50 -15 14 5 33 7 41 4 13 -5 15 10 15 105 0 67 -4 111 -10
111 -5 0 -10 -18 -10 -39z m-12 -63 c27 -27 4 -98 -32 -98 -30 0 -46 51 -30
94 7 19 45 21 62 4z"/>
<path d="M2400 1555 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2573 1546 c-25 -22 -28 -31 -28 -85 0 -77 22 -104 85 -104 64 0 94
35 94 109 0 49 -3 59 -27 80 -37 32 -87 32 -124 0z m94 -11 c27 -19 38 -71 23
-110 -13 -35 -26 -45 -62 -45 -58 0 -80 98 -33 145 28 29 42 31 72 10z"/>
<path d="M3432 1562 c-9 -6 -12 -34 -10 -103 l3 -94 43 -3 c75 -6 117 44 76
89 -13 14 -13 22 -4 39 14 26 -1 70 -24 70 -7 0 -26 2 -42 5 -16 3 -35 2 -42
-3z m86 -49 c2 -14 -4 -26 -18 -33 -31 -17 -50 -5 -50 30 0 29 2 31 33 28 24
-2 33 -8 35 -25z m-4 -69 c24 -9 19 -43 -8 -56 -35 -16 -56 -4 -56 32 0 25 4
30 24 30 13 0 31 -3 40 -6z"/>
<path d="M4040 1555 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5541 1551 c-36 -36 -40 -132 -7 -176 17 -23 69 -24 95 -1 39 36 33
163 -9 186 -29 15 -58 12 -79 -9z m63 -14 c22 -16 32 -91 17 -129 -14 -38 -57
-39 -71 -2 -15 38 -12 97 6 122 18 26 24 27 48 9z"/>
<path d="M7140 1555 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M7307 1564 c-4 -4 -7 -52 -7 -106 l0 -98 43 0 c23 0 53 7 65 16 27
19 30 69 5 83 -15 8 -16 12 -5 22 6 7 12 23 12 35 0 12 -6 26 -12 32 -17 13
-92 25 -101 16z m81 -51 c2 -14 -4 -26 -18 -33 -31 -17 -50 -5 -50 30 0 29 2
31 33 28 24 -2 33 -8 35 -25z m10 -95 c3 -22 -2 -29 -22 -34 -39 -9 -56 1 -56
36 0 30 2 31 38 28 32 -3 37 -7 40 -30z"/>
<path d="M2040 1460 c0 -82 3 -100 15 -100 11 0 15 12 15 50 l0 50 44 0 45 0
3 -47 c2 -26 9 -48 16 -51 9 -3 12 21 12 97 0 83 -3 101 -15 101 -11 0 -15
-11 -15 -40 l0 -40 -45 0 -45 0 0 40 c0 29 -4 40 -15 40 -12 0 -15 -18 -15
-100z"/>
<path d="M4452 1463 l3 -98 53 -3 c59 -3 73 15 15 20 l-38 3 -3 88 c-2 69 -6
87 -18 87 -12 0 -14 -16 -12 -97z"/>
<path d="M5210 1460 c0 -60 4 -100 10 -100 6 0 10 30 10 69 0 39 4 72 9 75 5
4 28 -28 51 -70 29 -54 46 -74 58 -72 24 5 31 198 7 198 -12 0 -15 -14 -15
-72 -1 -71 -1 -72 -18 -51 -9 11 -29 44 -44 72 -19 35 -34 51 -48 51 -19 0
-20 -7 -20 -100z"/>
<path d="M5719 1541 c-13 -11 -21 -23 -16 -27 4 -4 13 -2 19 4 23 23 29 11 26
-60 -3 -67 -5 -73 -25 -76 -47 -7 -21 -22 37 -22 58 0 84 15 38 22 -22 3 -23
8 -26 91 -3 95 -10 103 -53 68z"/>
<path d="M5940 1460 c0 -102 16 -140 22 -52 l3 47 45 0 45 0 3 -48 c2 -32 7
-47 15 -44 8 2 13 37 15 100 2 82 0 97 -13 97 -11 0 -15 -11 -15 -40 l0 -40
-50 0 -50 0 0 40 c0 22 -4 40 -10 40 -6 0 -10 -40 -10 -100z"/>
<path d="M2254 1504 c-18 -15 -17 -15 13 -10 22 4 37 0 48 -10 20 -20 11 -34
-24 -34 -15 0 -36 -9 -46 -20 -15 -17 -16 -24 -7 -45 10 -22 17 -25 62 -23
l50 2 0 68 c0 56 -3 69 -19 78 -28 14 -54 12 -77 -6z m76 -87 c0 -18 -21 -37
-41 -37 -22 0 -33 16 -25 35 7 19 66 20 66 2z"/>
<path d="M3030 1500 c-14 -14 -20 -33 -20 -63 0 -62 27 -84 98 -79 39 3 24 21
-20 24 -36 2 -44 7 -46 26 -3 20 0 22 47 22 48 0 51 2 51 25 0 32 -33 65 -65
65 -14 0 -34 -9 -45 -20z m68 -12 c23 -23 13 -38 -23 -38 -22 0 -35 5 -35 13
0 16 20 37 35 37 6 0 16 -5 23 -12z"/>
<path d="M3646 1511 c-3 -5 -15 -7 -26 -4 -19 5 -20 0 -20 -71 0 -61 3 -76 15
-76 11 0 15 13 15 53 0 59 7 73 35 78 11 3 19 9 18 14 -3 14 -30 18 -37 6z"/>
<path d="M3724 1504 c-18 -15 -17 -15 12 -10 35 6 71 -14 60 -32 -4 -7 -20
-12 -36 -12 -39 0 -67 -39 -50 -69 9 -17 19 -21 60 -19 l50 2 0 68 c0 56 -3
69 -19 78 -28 14 -54 12 -77 -6z m76 -87 c0 -18 -21 -37 -41 -37 -24 0 -33 18
-19 35 15 19 60 20 60 2z"/>
<path d="M4165 1510 c-4 -6 -15 -8 -26 -5 -18 6 -19 1 -19 -69 0 -44 4 -76 10
-76 6 0 10 22 10 48 0 55 18 86 46 80 16 -3 19 -13 22 -66 2 -45 7 -62 17 -62
10 0 15 17 17 63 3 59 4 62 30 65 27 3 27 2 30 -59 2 -39 8 -64 16 -67 19 -6
16 122 -4 142 -19 20 -34 20 -61 1 -18 -13 -22 -13 -33 0 -14 17 -46 20 -55 5z"/>
<path d="M4605 1495 c-50 -49 -21 -138 44 -138 47 0 76 33 75 85 -2 73 -70
103 -119 53z m87 -30 c24 -53 -26 -109 -68 -75 -17 15 -19 71 -2 88 21 21 57
14 70 -13z"/>
<path d="M4775 1495 c-48 -47 -22 -137 39 -137 15 0 35 7 44 17 16 16 15 17
-16 10 -42 -9 -62 7 -62 49 0 46 13 59 49 54 33 -5 44 15 15 26 -28 11 -44 6
-69 -19z"/>
<path d="M4918 1513 c-29 -7 -21 -26 9 -20 36 7 53 -1 53 -24 0 -14 -7 -19
-28 -19 -31 0 -62 -26 -62 -52 0 -26 24 -38 73 -36 l47 2 0 62 c0 84 -23 105
-92 87z m60 -105 c-4 -31 -58 -40 -58 -10 0 25 8 32 37 32 20 0 24 -5 21 -22z"/>
<path d="M6144 1504 c-18 -15 -17 -15 15 -10 35 6 61 -6 61 -30 0 -8 -11 -14
-26 -14 -42 0 -64 -17 -64 -51 0 -31 30 -52 58 -41 8 3 23 5 35 6 20 1 22 6
21 65 -1 50 -5 67 -19 78 -25 17 -57 17 -81 -3z m76 -87 c0 -18 -21 -37 -41
-37 -24 0 -33 18 -19 35 15 19 60 20 60 2z"/>
<path d="M6341 1511 c-7 -5 -22 -6 -32 -4 -17 5 -19 -1 -19 -71 0 -61 3 -76
15 -76 11 0 15 13 15 53 0 55 12 77 41 77 22 0 29 -20 29 -81 0 -68 18 -62 22
7 3 60 20 83 52 71 12 -5 16 -20 16 -67 0 -47 3 -60 15 -60 24 0 21 111 -4
141 -18 22 -22 23 -51 12 -22 -8 -39 -9 -51 -3 -22 12 -30 12 -48 1z"/>
<path d="M6601 1511 c-7 -5 -22 -6 -32 -4 -17 5 -19 -1 -19 -71 0 -61 3 -76
15 -76 11 0 15 13 15 53 0 55 12 77 41 77 22 0 29 -20 29 -81 0 -68 18 -62 22
7 3 60 20 83 52 71 12 -5 16 -20 16 -67 0 -47 3 -60 15 -60 21 0 21 107 -1
138 -17 24 -43 29 -62 10 -9 -9 -18 -9 -37 0 -31 14 -36 14 -54 3z"/>
<path d="M6824 1504 c-18 -15 -17 -15 15 -10 35 6 61 -6 61 -30 0 -8 -11 -14
-26 -14 -42 0 -64 -17 -64 -51 0 -31 30 -52 58 -41 8 3 23 5 35 6 20 1 22 6
22 64 0 51 -4 67 -20 78 -25 18 -57 18 -81 -2z m76 -94 c0 -24 -48 -40 -61
-19 -11 18 8 39 37 39 17 0 24 -6 24 -20z"/>
<path d="M7485 1495 c-50 -49 -21 -138 43 -138 47 0 72 27 72 80 0 77 -64 110
-115 58z m85 -31 c14 -37 2 -73 -26 -80 -32 -8 -54 11 -54 46 0 46 10 60 41
60 22 0 32 -6 39 -26z"/>
<path d="M7861 1511 c-7 -5 -22 -6 -32 -4 -17 5 -19 -1 -19 -71 0 -61 3 -76
15 -76 11 0 15 13 15 53 0 55 12 77 41 77 22 0 29 -20 29 -81 0 -68 18 -62 22
7 3 60 20 83 52 71 12 -5 16 -20 16 -67 0 -47 3 -60 15 -60 21 0 21 107 -1
138 -17 25 -46 29 -64 7 -11 -13 -15 -13 -33 0 -24 17 -36 18 -56 6z"/>
<path d="M8090 1500 c-14 -14 -20 -33 -20 -63 0 -62 27 -84 98 -79 39 3 24 21
-20 24 -36 2 -44 7 -46 26 -3 20 0 22 47 22 48 0 51 2 51 25 0 32 -33 65 -65
65 -14 0 -34 -9 -45 -20z m68 -12 c23 -23 13 -38 -23 -38 -22 0 -35 5 -35 13
0 16 20 37 35 37 6 0 16 -5 23 -12z"/>
<path d="M8286 1511 c-3 -5 -15 -7 -26 -4 -19 5 -20 0 -20 -71 0 -61 3 -76 15
-76 11 0 15 13 15 53 0 59 7 73 35 78 11 3 19 9 18 14 -3 14 -30 18 -37 6z"/>
<path d="M8548 1510 c-29 -17 -41 -73 -25 -113 16 -36 44 -49 92 -39 41 7 30
29 -12 24 -27 -3 -40 0 -50 14 -21 29 -16 34 37 34 57 0 69 14 49 52 -20 39
-54 49 -91 28z m60 -22 c24 -24 13 -38 -28 -38 -43 0 -49 9 -24 34 19 19 36
20 52 4z"/>
<path d="M8696 1507 c-26 -19 -17 -57 19 -77 17 -9 32 -25 33 -35 3 -15 -2
-17 -32 -13 -41 5 -51 -17 -11 -24 33 -7 69 13 73 40 3 16 -6 27 -33 43 -43
26 -44 55 -2 52 29 -2 37 11 11 21 -24 9 -38 7 -58 -7z"/>
<path d="M2400 1435 c0 -60 3 -75 15 -75 12 0 15 15 15 75 0 60 -3 75 -15 75
-12 0 -15 -15 -15 -75z"/>
<path d="M2770 1446 c0 -55 3 -67 21 -80 15 -10 28 -12 45 -6 13 5 31 7 39 4
13 -5 15 6 15 70 0 61 -3 76 -15 76 -11 0 -15 -13 -15 -53 0 -55 -12 -77 -41
-77 -22 0 -29 20 -29 81 0 27 -4 49 -10 49 -6 0 -10 -28 -10 -64z"/>
<path d="M4042 1438 c2 -46 7 -73 16 -76 9 -3 12 16 12 72 0 62 -3 76 -15 76
-13 0 -15 -12 -13 -72z"/>
<path d="M7140 1435 c0 -60 3 -75 15 -75 12 0 15 15 15 75 0 60 -3 75 -15 75
-12 0 -15 -15 -15 -75z"/>
<path d="M7640 1452 c0 -66 15 -93 50 -92 14 0 37 0 53 1 l27 0 0 74 c0 59 -3
75 -15 75 -10 0 -15 -15 -17 -56 -3 -60 -20 -83 -52 -71 -12 5 -16 20 -16 67
0 47 -3 60 -15 60 -12 0 -15 -13 -15 -58z"/>
<path d="M4100 1188 c0 -2 -16 -47 -35 -100 -19 -54 -31 -98 -26 -98 9 0 15
13 55 122 14 39 21 70 15 73 -5 4 -9 5 -9 3z"/>
<path d="M1807 1163 c-11 -10 -8 -143 3 -143 6 0 10 14 10 30 0 21 5 30 16 30
9 0 21 -13 27 -30 6 -16 15 -30 19 -30 11 0 10 18 -3 42 -8 15 -8 27 2 45 12
23 11 27 -10 44 -23 19 -52 25 -64 12z m51 -25 c8 -8 9 -15 1 -25 -18 -21 -39
-15 -39 12 0 27 18 33 38 13z"/>
<path d="M1940 1150 c-25 -25 -27 -91 -4 -114 18 -19 70 -21 80 -4 5 8 0 9
-16 5 -38 -10 -60 9 -60 52 0 45 19 65 55 59 28 -6 34 6 9 16 -27 10 -43 7
-64 -14z"/>
<path d="M2107 1163 c-11 -10 -8 -143 3 -143 6 0 10 24 10 53 1 51 1 52 18 31
9 -12 25 -36 34 -53 27 -49 38 -38 38 39 0 78 -17 96 -22 23 l-3 -48 -22 41
c-24 44 -46 67 -56 57z"/>
<path d="M2245 1160 c-10 -17 20 -44 40 -36 9 3 15 14 13 23 -4 21 -43 30 -53
13z m35 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"/>
<path d="M2454 1156 c-14 -11 -19 -26 -19 -58 1 -62 9 -78 43 -78 40 0 55 22
54 76 -1 68 -32 93 -78 60z m54 -60 c3 -50 -8 -69 -34 -59 -19 7 -28 67 -14
93 20 38 45 21 48 -34z"/>
<path d="M2573 1164 c-22 -10 -14 -20 15 -19 33 0 37 -25 5 -41 -25 -12 -20
-24 9 -24 14 0 19 -6 16 -22 -2 -19 -8 -23 -36 -20 -21 1 -31 -2 -27 -8 3 -5
22 -10 40 -10 43 0 66 33 42 58 -12 12 -13 19 -4 33 21 34 -20 70 -60 53z"/>
<path d="M2747 1163 c-4 -3 -7 -37 -7 -75 l0 -69 36 3 c41 3 61 26 49 54 -4
11 -7 31 -6 46 2 22 -3 30 -24 38 -31 12 -39 12 -48 3z m51 -36 c4 -20 -25
-34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m12 -67 c0 -16 -7 -20
-30 -20 -23 0 -30 4 -30 20 0 16 7 20 30 20 23 0 30 -4 30 -20z"/>
<path d="M2916 1148 c-18 -26 -21 -80 -6 -109 7 -12 21 -19 40 -19 35 0 50 23
50 79 0 65 -52 95 -84 49z m62 -49 c2 -32 -2 -50 -13 -59 -22 -18 -45 4 -45
45 0 49 10 66 34 63 17 -2 22 -11 24 -49z"/>
<path d="M3036 1154 c-33 -32 -13 -78 33 -76 17 0 31 -3 31 -8 0 -17 -34 -41
-51 -37 -11 3 -19 1 -19 -4 0 -19 59 -9 74 13 46 65 -15 165 -68 112z m59 -24
c8 -26 -1 -40 -26 -40 -22 0 -34 22 -25 45 8 22 44 18 51 -5z"/>
<path d="M3174 1162 c-31 -20 -44 -86 -24 -123 16 -30 67 -26 82 7 16 34 0 58
-41 62 -27 3 -30 6 -21 23 6 11 21 19 36 19 14 0 22 4 19 10 -7 12 -35 13 -51
2z m43 -88 c3 -8 -1 -23 -10 -31 -13 -13 -19 -14 -31 -3 -9 7 -16 21 -16 31 0
24 48 26 57 3z"/>
<path d="M3513 1163 c-25 -10 -12 -22 17 -16 27 5 30 3 30 -19 0 -13 -13 -39
-30 -58 -16 -19 -29 -38 -30 -42 0 -5 20 -8 45 -8 25 0 45 5 45 10 0 6 -14 10
-31 10 l-31 0 26 31 c50 58 23 118 -41 92z"/>
<path d="M3637 1152 c-17 -19 -23 -85 -11 -116 7 -19 55 -21 72 -4 15 15 15
51 0 66 -7 7 -23 12 -36 12 -34 0 -25 34 11 38 15 2 27 8 27 13 0 16 -46 10
-63 -9z m53 -86 c0 -25 -15 -36 -37 -29 -7 3 -13 16 -13 29 0 19 5 24 25 24
20 0 25 -5 25 -24z"/>
<path d="M3817 1152 c-20 -22 -23 -102 -5 -120 19 -19 66 -15 78 7 39 73 -23
168 -73 113z m57 -18 c21 -54 -7 -121 -39 -94 -15 13 -21 90 -8 103 12 13 41
7 47 -9z"/>
<path d="M4147 1163 c-13 -13 -7 -19 21 -18 34 0 36 -25 2 -42 l-24 -12 27
-10 c48 -19 27 -56 -25 -44 -18 4 -20 3 -12 -6 6 -7 27 -11 45 -9 27 2 35 8
37 27 2 13 -1 28 -6 33 -7 7 -7 18 0 34 8 17 8 27 -2 39 -13 15 -51 20 -63 8z"/>
<path d="M4267 1163 c-4 -3 -7 -21 -7 -40 0 -29 3 -33 25 -33 30 0 43 -24 24
-46 -8 -10 -21 -13 -36 -9 -13 3 -23 1 -23 -5 0 -15 66 -12 80 4 21 26 8 60
-27 71 -45 13 -46 45 -2 45 17 0 28 4 24 10 -7 11 -48 14 -58 3z"/>
<path d="M5096 1163 c-10 -10 -7 -143 4 -143 5 0 10 25 12 56 l3 56 30 -53
c44 -77 55 -75 55 10 0 79 -17 98 -22 24 l-3 -48 -28 47 c-29 50 -40 61 -51
51z"/>
<path d="M5242 1148 c2 -15 10 -23 23 -23 30 0 27 39 -3 43 -20 3 -23 0 -20
-20z m29 -1 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z"/>
<path d="M5447 1163 c-14 -14 -6 -20 19 -15 22 4 25 2 22 -19 -2 -15 -12 -25
-28 -29 -25 -6 -25 -6 8 -18 52 -18 36 -58 -20 -45 -18 4 -20 3 -12 -6 15 -17
65 -10 77 10 6 9 7 25 2 35 -4 11 -7 32 -6 48 2 16 -3 33 -11 37 -16 10 -42
12 -51 2z"/>
<path d="M5554 1156 c-13 -33 0 -61 29 -64 16 -2 31 -10 34 -18 8 -23 -17 -46
-44 -39 -13 3 -23 1 -23 -5 0 -16 66 -12 80 5 21 25 4 65 -30 72 -43 8 -42 43
1 43 17 0 28 4 24 10 -9 15 -65 12 -71 -4z"/>
<path d="M5677 1164 c-13 -14 -7 -20 21 -19 33 0 37 -25 5 -41 -25 -12 -20
-24 9 -24 11 0 18 -7 18 -19 0 -23 -20 -32 -51 -24 -15 4 -20 3 -15 -5 4 -7
23 -12 41 -12 42 0 62 27 41 56 -10 14 -11 22 -2 36 8 13 8 23 0 36 -11 18
-54 28 -67 16z"/>
<path d="M5807 1152 c-23 -25 -31 -71 -18 -104 18 -48 91 -34 91 17 0 29 -19
45 -52 45 -26 0 -28 2 -18 20 6 12 21 20 35 20 14 0 25 5 25 10 0 17 -46 11
-63 -8z m53 -80 c0 -18 -18 -42 -32 -42 -12 0 -30 39 -23 50 11 18 55 12 55
-8z"/>
<path d="M5916 1148 c-18 -26 -21 -80 -6 -109 7 -12 21 -19 40 -19 35 0 50 23
50 79 0 65 -52 95 -84 49z m62 -49 c2 -32 -2 -50 -13 -59 -22 -18 -45 4 -45
45 0 49 10 66 34 63 17 -2 22 -11 24 -49z"/>
<path d="M6036 1154 c-19 -19 -22 -104 -4 -122 19 -19 55 -14 72 10 46 65 -15
165 -68 112z m58 -26 c15 -56 -5 -105 -37 -92 -13 4 -17 18 -17 53 0 26 3 51
7 54 14 15 41 6 47 -15z"/>
<path d="M6157 1163 c-4 -3 -7 -21 -7 -39 0 -28 4 -33 27 -36 18 -2 29 -10 31
-22 5 -23 -16 -38 -45 -31 -13 3 -23 1 -23 -5 0 -15 66 -12 80 4 21 26 8 60
-28 71 -40 11 -39 39 1 43 15 2 25 8 22 13 -7 10 -48 12 -58 2z"/>
<path d="M6280 1150 c-14 -14 -20 -33 -20 -63 0 -48 13 -67 46 -67 29 0 46 23
42 57 -3 24 -8 28 -36 31 -25 3 -32 7 -28 19 3 8 6 17 6 19 0 2 14 4 31 4 17
0 28 4 24 10 -10 16 -44 11 -65 -10z m50 -84 c0 -25 -15 -36 -37 -29 -7 3 -13
16 -13 29 0 19 5 24 25 24 20 0 25 -5 25 -24z"/>
<path d="M6393 1163 c-22 -8 -14 -19 14 -18 39 0 41 -28 4 -74 -17 -21 -31
-42 -31 -45 0 -3 21 -6 46 -6 27 0 43 4 39 10 -3 6 -18 10 -31 10 l-26 0 26
31 c50 58 23 118 -41 92z"/>
<path d="M6506 1148 c-18 -26 -21 -80 -6 -109 7 -12 21 -19 40 -19 35 0 50 23
50 79 0 65 -52 95 -84 49z m62 -49 c2 -32 -2 -50 -13 -59 -22 -18 -45 4 -45
45 0 49 10 66 34 63 17 -2 22 -11 24 -49z"/>
<path d="M6627 1163 c-4 -3 -7 -21 -7 -39 0 -28 4 -33 27 -36 18 -2 29 -10 31
-22 5 -23 -16 -38 -45 -31 -13 3 -23 1 -23 -5 0 -15 66 -12 80 4 21 26 8 60
-28 71 -40 11 -39 39 1 43 15 2 25 8 22 13 -7 10 -48 12 -58 2z"/>
<path d="M7327 1163 c-11 -10 -8 -143 3 -143 6 0 10 14 10 30 0 23 5 30 19 30
11 0 23 5 26 10 4 6 -5 10 -19 10 -21 0 -26 5 -26 25 0 21 5 25 31 25 17 0 28
4 24 10 -7 11 -58 14 -68 3z"/>
<path d="M7546 1154 c-19 -19 -22 -104 -4 -122 7 -7 25 -12 40 -12 21 0 31 6
38 26 31 80 -24 159 -74 108z m58 -20 c10 -27 7 -69 -8 -89 -12 -16 -16 -17
-30 -6 -16 13 -23 91 -9 104 12 13 41 7 47 -9z"/>
<path d="M7663 1158 c-19 -24 -24 -70 -13 -104 10 -28 17 -34 40 -34 37 0 53
22 53 75 0 24 -5 51 -13 59 -15 19 -54 21 -67 4z m58 -39 c9 -36 0 -76 -18
-83 -21 -9 -30 -1 -36 34 -14 66 37 111 54 49z"/>
<path d="M7788 1158 c-23 -19 -31 -65 -18 -104 10 -28 17 -34 40 -34 38 0 55
25 54 76 -1 65 -36 93 -76 62z m53 -39 c9 -36 0 -76 -18 -83 -21 -9 -30 -1
-36 34 -14 66 37 111 54 49z"/>
<path d="M7903 1164 c-22 -10 -14 -20 15 -19 33 0 37 -25 5 -41 -25 -12 -20
-24 9 -24 14 0 19 -6 16 -22 -2 -19 -8 -23 -36 -20 -21 1 -31 -2 -27 -8 3 -5
22 -10 40 -10 43 0 66 33 42 58 -12 12 -13 19 -4 33 21 34 -20 70 -60 53z"/>
<path d="M8145 1150 c-12 -13 -18 -35 -17 -65 0 -55 6 -65 41 -65 32 0 51 16
51 45 0 28 -18 45 -48 45 -34 0 -25 34 11 38 33 4 36 22 4 22 -13 0 -32 -9
-42 -20z m55 -84 c0 -24 -23 -41 -41 -30 -5 3 -9 16 -9 30 0 19 5 24 25 24 20
0 25 -5 25 -24z"/>
<path d="M8256 1154 c-19 -19 -22 -104 -4 -122 19 -19 66 -15 78 7 38 71 -23
166 -74 115z m58 -20 c10 -27 7 -69 -8 -89 -12 -16 -16 -17 -30 -6 -16 13 -23
91 -9 104 12 13 41 7 47 -9z"/>
<path d="M8372 1158 c-7 -7 -12 -24 -12 -40 0 -30 28 -52 54 -42 24 9 20 -23
-5 -36 -11 -7 -27 -9 -35 -6 -8 3 -14 1 -14 -4 0 -17 56 -11 73 8 22 24 22 95
1 116 -18 19 -45 21 -62 4z m53 -38 c0 -38 -39 -35 -43 3 -3 25 0 28 20 25 17
-2 23 -9 23 -28z"/>
<path d="M8496 1151 c-18 -19 -25 -99 -11 -121 9 -14 58 -13 73 2 7 7 12 21
12 33 0 28 -18 45 -48 45 -34 0 -25 34 11 38 33 4 36 22 4 22 -13 0 -32 -9
-41 -19z m54 -85 c0 -24 -23 -41 -41 -30 -5 3 -9 16 -9 30 0 19 5 24 25 24 20
0 25 -5 25 -24z"/>
<path d="M8620 1123 c-38 -61 -38 -73 0 -73 20 0 30 -5 30 -15 0 -8 5 -15 10
-15 6 0 10 6 10 14 0 8 6 17 13 20 10 5 10 7 0 12 -8 3 -13 24 -13 49 0 24 -4
47 -10 50 -5 3 -23 -16 -40 -42z m30 -23 c0 -23 -4 -30 -20 -30 -20 0 -20 1
-5 30 9 17 18 30 20 30 3 0 5 -13 5 -30z"/>
<path d="M8847 1163 c-14 -13 -7 -21 14 -16 46 12 39 -46 -10 -91 -31 -28 -26
-36 24 -36 25 0 45 5 45 10 0 6 -14 10 -31 10 l-31 0 31 35 c33 38 39 63 19
83 -13 13 -50 16 -61 5z"/>
<path d="M8984 1162 c-31 -20 -44 -86 -24 -123 16 -30 67 -26 82 7 16 34 0 58
-41 62 -27 3 -30 6 -21 23 6 11 21 19 36 19 14 0 22 4 19 10 -7 12 -35 13 -51
2z m43 -88 c3 -8 -2 -23 -11 -32 -16 -16 -18 -16 -32 2 -8 11 -14 26 -14 33 0
19 49 16 57 -3z"/>
<path d="M9124 1162 c-11 -7 -64 -94 -64 -105 0 -4 16 -7 35 -7 24 0 35 -5 35
-15 0 -23 14 -18 27 10 6 14 7 25 2 25 -5 0 -9 20 -9 44 0 46 -8 60 -26 48z
m6 -57 c0 -31 -3 -35 -25 -35 l-24 0 20 35 c12 19 23 35 25 35 2 0 4 -16 4
-35z"/>
<path d="M9209 1158 c-23 -18 -30 -58 -18 -101 8 -32 14 -37 38 -37 35 0 50
17 57 62 9 62 -37 108 -77 76z m52 -35 c17 -46 -5 -98 -38 -86 -14 5 -19 94
-6 106 14 15 34 6 44 -20z"/>
<path d="M3277 1114 c-31 -51 -28 -64 14 -64 19 0 29 -5 29 -15 0 -23 14 -18
27 10 6 14 7 25 2 25 -5 0 -9 20 -9 45 0 61 -26 61 -63 -1z m43 -14 c0 -25 -4
-30 -25 -30 -24 0 -24 0 -7 30 22 38 32 38 32 0z"/>
<path d="M3399 1143 c-12 -15 -12 -16 4 -10 15 6 17 0 17 -43 0 -38 -4 -50
-15 -50 -8 0 -15 -4 -15 -10 0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -7 10
-15 10 -12 0 -15 13 -15 60 0 63 -13 77 -41 43z"/>
<path d="M3939 1143 c-12 -15 -12 -16 4 -10 15 5 17 0 17 -40 0 -32 -5 -50
-17 -59 -16 -12 -12 -13 30 -14 30 0 46 4 42 10 -3 6 -13 10 -21 10 -11 0 -14
14 -14 60 0 63 -13 77 -41 43z"/>
<path d="M4775 1150 c-6 -9 -45 -120 -45 -127 0 -12 20 1 25 17 5 14 15 20 37
20 21 0 31 -6 35 -20 5 -19 23 -29 23 -12 0 4 -10 35 -22 70 -20 58 -38 76
-53 52z m29 -36 c10 -26 7 -34 -15 -34 -15 0 -19 4 -15 16 3 9 6 20 6 25 0 15
17 10 24 -7z"/>
<path d="M4964 1147 c-6 -11 -23 -18 -47 -19 l-37 -1 0 -54 c0 -29 5 -53 10
-53 6 0 10 18 10 40 0 30 5 42 21 51 27 14 37 3 41 -46 3 -34 7 -40 28 -43 22
-2 23 -1 8 8 -23 14 -25 80 -3 80 8 0 15 5 15 10 0 6 -6 10 -14 10 -8 0 -16 8
-18 18 -3 16 -4 16 -14 -1z"/>
<path d="M7110 1090 c0 -40 4 -70 10 -70 6 0 10 26 10 58 l0 57 33 -57 c43
-79 57 -76 57 12 0 40 -4 70 -10 70 -5 0 -10 -24 -10 -52 l0 -53 -31 53 c-43
74 -59 69 -59 -18z"/>
<path d="M7260 1090 c0 -40 4 -70 10 -70 6 0 10 30 10 70 0 40 -4 70 -10 70
-6 0 -10 -30 -10 -70z"/>
<path d="M8024 1144 c-19 -15 -18 -15 4 -11 20 5 22 2 22 -44 0 -36 -4 -49
-14 -49 -8 0 -18 -4 -21 -10 -4 -6 12 -10 42 -10 42 1 46 2 31 14 -14 10 -18
28 -18 70 0 60 -10 69 -46 40z"/>
<path d="M8734 1144 c-19 -15 -18 -15 4 -11 20 5 22 2 22 -44 0 -36 -4 -49
-14 -49 -8 0 -18 -4 -21 -10 -4 -6 12 -10 42 -10 42 1 46 2 31 14 -14 10 -18
28 -18 70 0 60 -10 69 -46 40z"/>
<path d="M2336 1121 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M5330 1114 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M7430 1114 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M3730 1080 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"/>
<path d="M2330 1030 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5330 1030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7430 1030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5570 743 l-35 -98 -2148 -3 -2147 -2 0 69 0 69 -42 -2 c-37 -1 -44
-5 -52 -28 -6 -18 -6 -38 0 -55 8 -23 16 -28 42 -28 19 0 32 -5 32 -12 0 -10
-66 -13 -305 -13 -197 0 -305 -4 -305 -10 0 -6 111 -10 313 -10 171 0 2537 0
5257 0 2720 0 5133 0 5363 0 271 0 417 3 417 10 0 7 -143 10 -410 10 l-410 0
25 67 c14 36 23 68 20 71 -9 9 -22 -11 -35 -52 l-13 -40 -16 44 c-9 24 -22 45
-29 48 -9 3 -7 -12 8 -50 35 -92 36 -91 -81 -85 -56 4 -107 11 -115 18 -17 14
-18 69 -3 103 7 13 24 30 40 36 61 25 111 -16 90 -73 -16 -41 -34 -34 -29 12
3 39 3 39 -32 37 -28 -1 -38 -7 -49 -30 -21 -44 -5 -60 58 -63 51 -1 53 0 67
33 17 40 10 80 -16 94 -34 18 -97 12 -125 -13 -31 -26 -45 -100 -26 -136 l12
-21 -2670 0 -2669 0 34 91 c36 96 39 109 26 109 -4 0 -23 -44 -42 -97z m-4352
-27 c-2 -26 -7 -31 -28 -31 -20 0 -26 6 -28 30 -3 19 2 33 14 42 25 18 46 -2
42 -41z m9762 19 c0 -27 -25 -44 -40 -29 -15 15 0 54 21 54 14 0 19 -7 19 -25z"/>
<path d="M11826 824 c-9 -8 -16 -22 -16 -30 0 -8 -5 -14 -12 -14 -9 0 -9 -3 0
-12 7 -7 12 -34 12 -60 0 -26 5 -48 10 -48 6 0 10 23 10 50 0 38 4 50 15 50 8
0 15 5 15 10 0 6 -7 10 -15 10 -22 0 -18 31 5 37 11 3 17 9 14 14 -8 13 -21
11 -38 -7z"/>
<path d="M5490 745 c0 -50 4 -85 10 -85 6 0 10 35 10 85 0 50 -4 85 -10 85 -6
0 -10 -35 -10 -85z"/>
<path d="M9850 745 c0 -50 4 -85 10 -85 6 0 10 35 10 85 0 50 -4 85 -10 85 -6
0 -10 -35 -10 -85z"/>
<path d="M10810 801 c0 -25 -3 -28 -25 -23 -35 6 -55 -14 -55 -58 0 -46 15
-60 63 -58 l37 1 0 84 c0 49 -4 83 -10 83 -5 0 -10 -13 -10 -29z m0 -81 c0
-18 -7 -33 -18 -39 -24 -13 -42 4 -42 39 0 35 18 52 42 39 11 -6 18 -21 18
-39z"/>
<path d="M11340 745 c0 -50 4 -85 10 -85 6 0 10 20 10 44 0 44 19 74 40 61 5
-3 10 -28 10 -56 0 -27 5 -49 10 -49 7 0 10 22 8 58 l-3 57 -32 0 c-29 0 -33
3 -33 27 0 16 -4 28 -10 28 -6 0 -10 -35 -10 -85z"/>
<path d="M750 741 l0 -81 39 0 c58 0 91 41 57 72 -10 10 -11 17 -2 30 18 29
-4 53 -53 56 l-41 3 0 -80z m75 34 c0 -14 -8 -21 -27 -23 -24 -3 -28 0 -28 23
0 23 4 26 28 23 19 -2 27 -9 27 -23z m10 -70 c0 -15 -8 -21 -32 -23 -30 -3
-33 -1 -33 23 0 24 3 26 33 23 24 -2 32 -8 32 -23z"/>
<path d="M1662 743 l3 -78 32 -3 c18 -2 41 2 53 8 23 13 27 58 5 66 -11 5 -12
9 -2 25 19 30 -2 53 -52 57 l-42 3 3 -78z m73 32 c0 -14 -8 -21 -27 -23 -24
-3 -28 0 -28 23 0 23 4 26 28 23 19 -2 27 -9 27 -23z m10 -70 c0 -15 -8 -21
-32 -23 -30 -3 -33 -1 -33 23 0 24 3 26 33 23 24 -2 32 -8 32 -23z"/>
<path d="M1807 813 c-11 -11 -8 -153 3 -153 6 0 10 27 10 60 0 33 3 60 8 60 4
0 22 -27 40 -61 18 -33 39 -58 45 -56 15 5 22 157 7 157 -6 0 -10 -24 -10 -55
0 -30 -4 -55 -8 -55 -4 0 -21 25 -37 55 -28 52 -43 64 -58 48z"/>
<path d="M1992 768 c-11 -29 -25 -65 -31 -80 -7 -20 -7 -28 0 -28 6 0 13 9 16
20 4 16 14 20 43 20 30 0 40 -5 45 -20 6 -18 25 -29 25 -14 0 24 -55 154 -66
154 -7 0 -21 -24 -32 -52z m52 -25 c6 -20 4 -23 -18 -23 -29 0 -31 6 -15 47
11 30 19 24 33 -24z"/>
<path d="M2300 801 c-13 -25 -13 -97 0 -122 15 -27 64 -25 84 3 18 26 21 90 6
119 -7 13 -21 19 -45 19 -24 0 -38 -6 -45 -19z m64 -7 c9 -9 16 -32 16 -54 0
-32 -19 -70 -35 -70 -16 0 -35 38 -35 70 0 32 19 70 35 70 2 0 11 -7 19 -16z"/>
<path d="M2440 805 c-21 -25 -24 -92 -6 -120 21 -31 70 -35 86 -6 14 27 13
114 -2 129 -17 17 -63 15 -78 -3z m58 -7 c7 -7 12 -33 12 -60 0 -51 -22 -76
-51 -58 -20 13 -19 107 1 120 20 13 24 12 38 -2z"/>
<path d="M2579 799 l-24 -20 23 6 c21 6 22 4 22 -49 0 -42 -4 -56 -14 -56 -8
0 -18 -4 -21 -10 -4 -6 12 -10 39 -10 25 0 46 5 46 10 0 6 -7 10 -15 10 -12 0
-15 14 -15 70 0 39 -4 70 -9 70 -5 0 -19 -9 -32 -21z"/>
<path d="M2750 801 c-5 -11 -10 -38 -10 -61 0 -57 14 -80 50 -80 37 0 60 32
60 83 0 57 -14 77 -55 77 -24 0 -38 -6 -45 -19z m64 -7 c9 -9 16 -32 16 -54 0
-32 -19 -70 -35 -70 -16 0 -35 38 -35 70 0 32 19 70 35 70 2 0 11 -7 19 -16z"/>
<path d="M2896 805 c-23 -23 -30 -79 -14 -112 11 -23 20 -28 48 -28 29 0 36 5
44 28 14 37 -2 61 -43 65 -50 5 -36 45 19 55 22 4 21 4 -7 6 -17 0 -38 -6 -47
-14z m62 -92 c3 -23 -26 -46 -45 -35 -10 6 -17 34 -14 55 0 5 13 7 29 5 19 -2
28 -9 30 -25z"/>
<path d="M3030 773 c-38 -61 -38 -73 1 -73 22 0 32 -5 36 -20 3 -11 8 -20 12
-20 11 0 29 50 20 56 -5 3 -9 27 -9 55 0 64 -20 65 -60 2z m36 -49 c-5 -14
-46 -20 -46 -8 0 4 10 23 23 43 l22 36 3 -28 c2 -16 1 -35 -2 -43z"/>
<path d="M3140 801 c-24 -46 -1 -87 45 -79 19 4 25 1 25 -13 0 -22 -32 -41
-54 -33 -9 3 -16 1 -16 -5 0 -16 56 -13 73 4 23 22 30 71 17 110 -10 31 -16
35 -45 35 -24 0 -38 -6 -45 -19z m56 1 c7 -4 14 -18 15 -32 4 -22 0 -25 -26
-25 -33 0 -45 25 -25 50 14 17 20 18 36 7z"/>
<path d="M3389 803 c-8 -9 -14 -37 -14 -63 0 -26 6 -54 14 -63 19 -23 68 -22
81 2 14 27 13 114 -2 129 -17 17 -63 15 -79 -5z m59 -5 c7 -7 12 -33 12 -60 0
-50 -22 -76 -50 -58 -18 11 -19 92 -1 113 16 20 23 21 39 5z"/>
<path d="M3514 809 c-5 -9 0 -10 18 -6 33 9 57 -15 38 -37 -6 -8 -21 -17 -33
-19 -23 -4 -18 -7 28 -22 19 -6 20 -41 1 -48 -8 -3 -24 -3 -35 0 -12 3 -21 1
-21 -6 0 -6 17 -11 40 -11 33 0 42 4 52 25 9 20 8 28 -5 43 -12 13 -14 21 -6
29 6 6 9 21 7 34 -2 20 -10 25 -40 27 -20 2 -39 -2 -44 -9z"/>
<path d="M3649 803 c-8 -9 -14 -37 -14 -63 0 -26 6 -54 14 -63 19 -23 68 -22
81 2 14 27 13 114 -2 129 -17 17 -63 15 -79 -5z m59 -5 c7 -7 12 -33 12 -60 0
-36 -4 -50 -19 -57 -14 -8 -22 -7 -32 5 -17 21 -17 87 0 108 16 19 23 20 39 4z"/>
<path d="M3780 801 c-14 -27 -13 -114 2 -129 16 -16 63 -15 77 2 19 23 25 83
11 116 -10 25 -18 30 -46 30 -23 0 -37 -6 -44 -19z m60 -1 c10 -6 15 -26 15
-60 0 -56 -16 -76 -47 -59 -31 17 -21 130 12 129 3 0 12 -5 20 -10z"/>
<path d="M3969 803 c-8 -9 -14 -37 -14 -63 0 -26 6 -54 14 -63 16 -20 62 -22
79 -5 16 16 16 120 0 136 -17 17 -63 15 -79 -5z m59 -5 c7 -7 12 -33 12 -60 0
-36 -4 -50 -19 -57 -14 -8 -22 -7 -31 4 -17 21 -18 89 -1 109 16 19 23 20 39
4z"/>
<path d="M4099 804 c-17 -21 -18 -107 0 -127 16 -20 62 -22 79 -5 16 16 16
120 0 136 -17 17 -63 15 -79 -4z m59 -6 c7 -7 12 -33 12 -60 0 -36 -4 -50 -19
-57 -14 -8 -22 -7 -31 4 -16 20 -16 90 0 110 15 18 22 19 38 3z"/>
<path d="M4229 804 c-17 -21 -18 -107 0 -127 16 -20 62 -22 79 -5 16 16 16
120 0 136 -17 17 -63 15 -79 -4z m59 -6 c7 -7 12 -33 12 -60 0 -36 -4 -50 -19
-57 -14 -8 -22 -7 -31 4 -16 20 -16 90 0 110 15 18 22 19 38 3z"/>
<path d="M4354 808 c-5 -8 0 -9 16 -5 13 3 30 1 37 -4 17 -15 -1 -49 -27 -49
-30 0 -24 -18 7 -22 18 -2 29 -10 31 -22 5 -25 -15 -36 -52 -29 -21 4 -27 3
-22 -5 8 -13 65 -16 82 -4 19 13 23 39 9 58 -9 13 -10 24 -2 41 7 16 7 27 -3
38 -15 18 -66 20 -76 3z"/>
<path d="M4502 767 c-18 -29 -32 -56 -32 -60 0 -4 16 -7 35 -7 28 0 35 -4 35
-20 0 -11 5 -20 10 -20 6 0 10 7 10 15 0 7 7 18 16 23 15 8 15 10 0 15 -12 5
-16 20 -16 57 0 66 -17 65 -58 -3z m38 -12 c0 -34 -21 -57 -37 -41 -6 6 25 76
34 76 1 0 3 -16 3 -35z"/>
<path d="M4631 770 c-17 -28 -31 -55 -31 -60 0 -6 14 -10 31 -10 22 0 32 -5
36 -20 3 -11 8 -20 12 -20 11 0 29 50 20 56 -5 3 -9 27 -9 55 0 65 -19 65 -59
-1z m35 -46 c-5 -14 -46 -20 -46 -8 0 4 10 23 23 43 l22 36 3 -28 c2 -16 1
-35 -2 -43z"/>
<path d="M4744 809 c-5 -9 0 -10 17 -6 13 3 30 2 38 -2 18 -12 6 -41 -19 -48
-25 -7 -26 -23 -1 -23 10 0 24 -6 30 -14 18 -21 -13 -48 -46 -40 -18 5 -24 3
-19 -5 9 -15 69 -14 84 1 16 16 15 43 -3 58 -11 9 -12 16 -4 26 6 7 9 23 7 36
-2 19 -10 24 -40 26 -20 2 -39 -2 -44 -9z"/>
<path d="M4876 798 c-18 -25 -21 -74 -6 -112 8 -21 16 -26 44 -26 42 0 56 20
56 80 0 23 -5 50 -10 61 -15 27 -64 25 -84 -3z m62 0 c7 -7 12 -33 12 -60 0
-50 -22 -76 -49 -59 -22 14 -26 94 -6 114 20 20 27 21 43 5z"/>
<path d="M5220 810 c0 -5 11 -10 25 -10 24 0 25 -2 25 -70 0 -40 4 -70 10 -70
6 0 10 30 10 70 0 68 1 70 25 70 14 0 25 5 25 10 0 6 -27 10 -60 10 -33 0 -60
-4 -60 -10z"/>
<path d="M5650 740 c0 -47 4 -80 10 -80 6 0 10 16 10 35 0 32 2 35 30 35 17 0
30 5 30 10 0 6 -13 10 -30 10 -25 0 -30 4 -30 25 0 21 5 25 30 25 17 0 30 5
30 10 0 6 -18 10 -40 10 l-40 0 0 -80z"/>
<path d="M6186 798 c-19 -27 -21 -77 -4 -113 14 -32 71 -36 88 -6 14 27 13
114 -2 129 -20 20 -65 14 -82 -10z m62 0 c7 -7 12 -33 12 -60 0 -51 -22 -76
-51 -58 -20 13 -19 107 1 120 20 13 24 12 38 -2z"/>
<path d="M6314 809 c-5 -9 -1 -10 15 -6 60 15 66 -22 16 -87 -19 -26 -35 -48
-35 -51 0 -3 23 -5 50 -5 28 0 50 5 50 10 0 6 -16 10 -36 10 l-36 0 32 36 c23
27 30 44 28 67 -3 29 -7 32 -40 35 -21 2 -39 -2 -44 -9z"/>
<path d="M6461 770 c-17 -28 -31 -55 -31 -60 0 -6 16 -10 35 -10 28 0 35 -4
35 -20 0 -11 3 -20 8 -20 12 0 31 49 21 56 -5 3 -9 27 -9 55 0 65 -19 65 -59
-1z m30 -53 c-5 -5 -17 -7 -25 -4 -14 5 -13 11 7 44 l22 38 3 -34 c2 -18 -1
-38 -7 -44z"/>
<path d="M6647 814 c-11 -11 -8 -49 3 -60 8 -8 6 -15 -5 -24 -34 -28 -10 -70
40 -70 50 0 74 42 40 70 -11 9 -12 16 -4 26 6 7 9 23 7 36 -2 18 -10 24 -38
26 -20 2 -39 0 -43 -4z m59 -19 c4 -8 0 -22 -7 -31 -11 -13 -14 -13 -27 0 -17
17 -9 46 13 46 9 0 18 -7 21 -15z m4 -81 c18 -21 -9 -49 -39 -39 -23 7 -27 25
-9 43 16 16 33 15 48 -4z"/>
<path d="M6774 785 c-17 -41 -18 -64 -4 -100 9 -23 15 -26 47 -23 31 2 39 8
47 31 13 37 -2 61 -43 65 -17 2 -31 9 -31 16 0 21 28 39 50 33 11 -3 20 -1 20
4 0 5 -16 9 -36 9 -31 0 -37 -4 -50 -35z m74 -72 c3 -23 -26 -47 -44 -35 -14
10 -25 42 -18 53 3 6 18 9 32 7 19 -2 28 -10 30 -25z"/>
<path d="M6900 801 c-5 -11 -10 -38 -10 -61 0 -23 5 -50 10 -61 15 -27 64 -25
84 3 18 26 21 90 6 119 -15 26 -75 26 -90 0z m64 -7 c9 -9 16 -32 16 -54 0
-32 -19 -70 -35 -70 -2 0 -11 7 -19 16 -18 18 -21 76 -6 105 13 23 24 24 44 3z"/>
<path d="M7084 809 c-5 -9 0 -10 17 -6 13 3 30 2 38 -2 20 -13 9 -46 -17 -49
-27 -4 -30 -22 -3 -22 10 0 25 -7 32 -16 11 -14 10 -18 -5 -30 -12 -8 -29 -11
-44 -7 -17 4 -23 2 -18 -6 9 -15 69 -14 84 1 16 16 15 43 -3 58 -11 9 -12 16
-4 26 6 7 9 23 7 36 -2 19 -10 24 -40 26 -20 2 -39 -2 -44 -9z"/>
<path d="M7232 766 c-17 -30 -32 -57 -32 -60 0 -3 16 -6 36 -6 27 0 37 -5 41
-20 7 -25 23 -26 23 -1 0 11 6 22 13 24 10 4 10 6 0 6 -9 1 -13 19 -13 56 0
75 -23 75 -68 1z m44 -42 c-6 -16 -46 -19 -46 -3 0 6 10 25 23 42 l22 32 3
-28 c2 -16 1 -35 -2 -43z"/>
<path d="M7362 766 c-17 -30 -32 -57 -32 -60 0 -3 16 -6 36 -6 27 0 37 -5 41
-20 7 -25 23 -26 23 -1 0 11 6 22 13 24 10 4 10 6 0 6 -9 1 -13 19 -13 56 0
75 -23 75 -68 1z m44 -42 c-6 -16 -46 -19 -46 -3 0 6 10 25 23 42 l22 32 3
-28 c2 -16 1 -35 -2 -43z"/>
<path d="M7720 801 c-5 -11 -10 -38 -10 -61 0 -23 5 -50 10 -61 14 -25 61 -25
83 -1 19 21 23 92 7 123 -15 26 -75 26 -90 0z m64 -7 c9 -9 16 -32 16 -54 0
-32 -19 -70 -35 -70 -2 0 -11 7 -19 16 -18 18 -21 76 -6 105 13 23 24 24 44 3z"/>
<path d="M7854 809 c-5 -9 -1 -10 16 -6 26 7 50 -7 50 -29 0 -8 -16 -31 -35
-53 -19 -21 -35 -44 -35 -50 0 -7 20 -11 50 -11 28 0 50 5 50 10 0 6 -16 10
-35 10 l-36 0 31 39 c22 27 31 47 28 67 -3 25 -8 29 -40 32 -21 2 -39 -2 -44
-9z"/>
<path d="M8000 773 c-38 -61 -38 -73 1 -73 22 0 32 -5 36 -20 7 -25 23 -26 23
-1 0 11 6 22 13 24 10 4 10 6 0 6 -9 1 -13 19 -13 56 0 71 -19 73 -60 8z m36
-49 c-5 -14 -46 -20 -46 -8 0 4 10 23 23 43 l22 36 3 -28 c2 -16 1 -35 -2 -43z"/>
<path d="M8177 814 c-11 -11 -8 -49 3 -60 8 -8 6 -15 -5 -24 -34 -28 -10 -70
40 -70 50 0 74 42 40 70 -11 9 -12 16 -4 26 6 7 9 23 7 36 -2 18 -10 24 -38
26 -20 2 -39 0 -43 -4z m59 -19 c4 -8 0 -22 -7 -31 -11 -13 -14 -13 -27 0 -17
17 -9 46 13 46 9 0 18 -7 21 -15z m4 -80 c10 -12 10 -18 0 -30 -7 -8 -18 -15
-25 -15 -7 0 -18 7 -25 15 -10 12 -10 18 0 30 7 8 18 15 25 15 7 0 18 -7 25
-15z"/>
<path d="M8304 785 c-17 -41 -17 -52 -3 -94 10 -29 15 -32 47 -29 30 2 38 8
46 31 14 38 -3 62 -43 62 -22 0 -31 4 -31 16 0 23 26 42 50 36 11 -3 20 -1 20
4 0 5 -16 9 -36 9 -31 0 -37 -4 -50 -35z m75 -69 c4 -23 -24 -47 -45 -39 -8 3
-14 18 -14 35 0 26 3 29 27 26 18 -2 29 -10 32 -22z"/>
<path d="M8430 801 c-5 -11 -10 -38 -10 -61 0 -23 5 -50 10 -61 13 -23 62 -26
84 -3 18 18 22 95 6 125 -15 26 -75 26 -90 0z m70 -10 c15 -29 12 -87 -6 -105
-8 -9 -17 -16 -19 -16 -16 0 -35 38 -35 70 0 58 38 90 60 51z"/>
<path d="M8614 809 c-5 -9 -1 -10 15 -6 60 15 68 -30 16 -90 -19 -23 -35 -44
-35 -47 0 -3 23 -6 50 -6 28 0 50 5 50 10 0 6 -16 10 -36 10 l-36 0 32 36 c23
27 30 44 28 67 -3 29 -7 32 -40 35 -21 2 -39 -2 -44 -9z"/>
<path d="M8757 814 c-10 -11 -9 -52 2 -59 6 -3 3 -18 -7 -34 -21 -37 -4 -61
42 -61 51 0 75 42 41 70 -11 9 -12 16 -4 26 6 7 9 23 7 36 -2 18 -10 24 -38
26 -20 2 -39 0 -43 -4z m59 -19 c4 -8 0 -22 -7 -31 -11 -13 -14 -13 -27 0 -17
17 -9 46 13 46 9 0 18 -7 21 -15z m4 -80 c10 -12 10 -18 0 -30 -7 -8 -18 -15
-25 -15 -7 0 -18 7 -25 15 -10 12 -10 18 0 30 7 8 18 15 25 15 7 0 18 -7 25
-15z"/>
<path d="M8870 811 c0 -6 18 -11 40 -13 l40 -3 -30 -63 c-34 -72 -34 -72 -20
-72 12 0 70 123 70 146 0 10 -14 14 -50 14 -27 0 -50 -4 -50 -9z"/>
<path d="M9240 740 l0 -80 45 0 c25 0 45 5 45 10 0 6 -16 10 -36 10 -33 0 -35
2 -32 27 2 22 10 29 33 34 l30 6 -30 4 c-22 3 -31 10 -33 27 -3 19 1 22 32 22
20 0 36 5 36 10 0 6 -20 10 -45 10 l-45 0 0 -80z"/>
<path d="M9450 740 c0 -94 18 -108 22 -17 l3 62 24 -63 c13 -34 29 -61 35 -59
6 2 22 32 36 68 l25 64 3 -67 c4 -95 22 -85 22 12 0 102 -18 108 -59 18 -27
-60 -29 -62 -39 -38 -32 82 -43 100 -56 100 -13 0 -16 -14 -16 -80z"/>
<path d="M9785 810 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M10005 810 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M894 769 c-4 -8 1 -9 15 -4 23 7 55 -11 45 -26 -3 -5 -16 -9 -28 -9
-13 0 -29 -7 -37 -16 -21 -26 1 -49 47 -49 36 0 39 2 42 31 5 51 -13 84 -47
84 -16 0 -33 -5 -37 -11z m61 -69 c0 -22 -32 -37 -47 -22 -11 11 -9 16 7 28
22 16 40 14 40 -6z"/>
<path d="M1010 718 c0 -32 4 -58 10 -58 6 0 10 18 10 39 0 43 22 74 45 65 10
-4 15 -21 15 -55 0 -27 5 -49 10 -49 12 0 13 89 2 107 -4 7 -26 11 -50 10
l-42 -1 0 -58z"/>
<path d="M1280 733 c0 -64 8 -72 58 -70 l42 1 0 58 c0 32 -4 58 -10 58 -5 0
-10 -18 -10 -39 0 -47 -24 -78 -46 -60 -8 7 -14 30 -14 55 0 24 -4 44 -10 44
-5 0 -10 -21 -10 -47z"/>
<path d="M1420 761 c-5 -11 -10 -29 -10 -41 0 -42 18 -60 60 -60 22 0 40 5 40
10 0 6 -10 8 -22 5 -27 -7 -64 17 -54 33 4 7 23 12 42 12 26 0 34 4 34 18 0
42 -70 60 -90 23z m68 -14 c3 -12 -3 -17 -21 -17 -31 0 -40 10 -25 27 15 18
42 12 46 -10z"/>
<path d="M2180 771 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M5366 758 c-33 -46 -6 -98 50 -98 19 0 34 5 34 11 0 6 -7 8 -16 5
-26 -10 -54 3 -54 25 0 16 7 19 40 19 41 0 47 8 30 41 -15 27 -64 25 -84 -3z
m63 -1 c6 -8 8 -17 4 -20 -12 -12 -53 -7 -53 6 0 28 30 37 49 14z"/>
<path d="M5765 770 c-4 -6 6 -9 26 -8 25 2 34 -1 37 -14 2 -13 -3 -18 -20 -18
-34 0 -50 -14 -46 -41 3 -21 9 -24 46 -25 l42 -2 0 43 c0 63 -7 75 -45 75 -19
0 -37 -5 -40 -10z m60 -60 c7 -12 -12 -40 -27 -40 -16 0 -21 32 -7 41 18 12
26 11 34 -1z"/>
<path d="M5880 775 c0 -3 7 -16 16 -30 14 -22 14 -26 -2 -50 -21 -32 -9 -47
13 -16 l16 24 18 -24 c23 -31 37 -17 15 16 -16 25 -16 28 -1 52 22 33 9 45
-14 14 l-19 -24 -11 21 c-10 20 -31 31 -31 17z"/>
<path d="M6060 765 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M9664 768 c-5 -8 -2 -9 11 -4 10 3 26 4 36 1 27 -9 24 -35 -3 -35
-34 0 -50 -15 -46 -41 3 -21 9 -24 46 -25 l42 -2 0 43 c0 24 -3 50 -6 59 -7
19 -69 22 -80 4z m61 -58 c7 -12 -12 -40 -27 -40 -16 0 -21 32 -7 41 18 12 26
11 34 -1z"/>
<path d="M9790 720 c0 -33 4 -60 10 -60 6 0 10 27 10 60 0 33 -4 60 -10 60 -6
0 -10 -27 -10 -60z"/>
<path d="M9900 770 c0 -5 9 -10 20 -10 25 0 25 -7 0 -45 -29 -43 -25 -55 15
-55 40 0 48 17 10 22 l-25 3 25 37 c33 49 32 58 -10 58 -19 0 -35 -4 -35 -10z"/>
<path d="M10010 720 c0 -33 4 -60 10 -60 6 0 10 27 10 60 0 33 -4 60 -10 60
-6 0 -10 -27 -10 -60z"/>
<path d="M10060 718 c0 -32 4 -58 10 -58 6 0 10 20 10 45 0 33 5 47 18 54 27
15 42 -5 42 -56 0 -24 5 -43 10 -43 6 0 10 19 10 43 0 46 14 69 36 60 9 -3 14
-22 14 -54 0 -28 5 -49 10 -49 7 0 10 22 8 58 -3 51 -5 57 -26 60 -13 2 -29
-2 -37 -8 -8 -7 -15 -7 -19 -1 -4 5 -24 9 -46 8 l-40 -1 0 -58z"/>
<path d="M10270 771 c0 -6 12 -9 27 -8 19 1 28 -3 31 -15 3 -13 -3 -18 -21
-18 -31 0 -51 -23 -42 -50 6 -18 13 -21 48 -18 38 3 42 6 45 34 2 16 -2 42 -7
57 -9 22 -17 27 -46 27 -19 0 -35 -4 -35 -9z m60 -71 c0 -24 -26 -38 -42 -22
-8 8 -8 15 2 27 18 21 40 19 40 -5z"/>
<path d="M10520 718 c0 -69 18 -78 22 -11 2 39 7 50 28 60 24 12 24 12 -12 11
l-38 -1 0 -59z"/>
<path d="M10610 771 c0 -6 12 -9 27 -8 19 1 28 -3 31 -15 3 -13 -3 -18 -21
-18 -31 0 -51 -23 -42 -50 6 -18 13 -21 48 -18 38 3 42 6 45 34 2 16 -2 42 -7
57 -9 22 -17 27 -46 27 -19 0 -35 -4 -35 -9z m60 -71 c0 -24 -26 -38 -42 -22
-8 8 -8 15 2 27 18 21 40 19 40 -5z"/>
<path d="M11215 770 c-4 -6 6 -9 26 -8 25 2 34 -1 37 -14 2 -13 -3 -18 -20
-18 -34 0 -50 -14 -46 -41 3 -21 9 -24 46 -25 l42 -2 0 43 c0 63 -7 75 -45 75
-19 0 -37 -5 -40 -10z m65 -64 c0 -18 -27 -39 -40 -31 -14 9 -13 32 3 38 23
10 37 7 37 -7z"/>
<path d="M11474 755 c-13 -20 -15 -33 -7 -58 8 -29 12 -32 48 -32 33 0 42 4
53 28 19 40 -2 81 -45 85 -25 3 -35 -2 -49 -23z m64 3 c16 -16 15 -63 -2 -77
-17 -14 -46 -4 -48 18 -5 57 21 88 50 59z"/>
<path d="M11612 768 c-17 -17 -15 -83 2 -97 44 -37 110 20 90 76 -7 22 -16 29
-44 31 -19 2 -41 -3 -48 -10z m62 -14 c9 -8 16 -24 16 -34 0 -18 -25 -50 -39
-50 -15 0 -31 25 -31 50 0 25 16 50 31 50 4 0 15 -7 23 -16z"/>
<path d="M11880 718 c0 -32 4 -58 10 -58 6 0 10 18 10 39 0 31 6 44 26 60 l26
20 -36 -1 -36 -1 0 -59z"/>
<path d="M7520 730 c0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -27 10 -60 10
-33 0 -60 -4 -60 -10z"/>
<path d="M9373 723 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2180 675 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M6060 675 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M11740 675 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M7525 409 c-2266 -3 -4837 -7 -5712 -8 l-1593 -1 0 -36 0 -35 4998 6
c2748 3 5633 8 6410 11 l1412 6 0 34 0 34 -697 -2 c-384 -1 -2552 -5 -4818 -9z"/>
</g>
</svg>
`

export function printBc(provider, details, po,curr) {
  let tableContent = ``;
  let total = 0;
  let timbre = 0;
  let tva = 0;
  for (var i = 0; i < details.length; i++) {
    let s = `
      <tr>
          <td>${
            details[i].pod_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].pod_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].pod_qty_ord}</td>
          <td>${details[i].pod_um}</td>
          <td>${details[i].pod_price}</td>
          <td>${details[i].pod_taxc}</td>
          <td>${details[i].pod_price * details[i].pod_qty_ord}</td>
        </tr>
      `;
    total += details[i].pod_price * details[i].pod_qty_ord;
    if (po.po_taxable)
      tva +=
        details[i].pod_price *
        details[i].pod_qty_ord *
        (details[i].pod_taxc ? details[i].pod_taxc / 100 : 0);

    tableContent = tableContent + s;
  }
  console.log(tva);
  let popupWin;
  if (po.po_cr_terms == "ES") {
    timbre = (total + tva) / 100;
  } else {
    timbre = 0;
  }
  if (timbre > 10000) {
    timbre = 10000;
  }
 
  const date = new Date();
  console.log(NumberToLetter(Number((total + tva + timbre).toFixed(2))))
  let totalstring = replace(
    NumberToLetter(Number((total + tva + timbre).toFixed(2))),
    "Dinars Algérien",
    curr.cu_desc
  );
  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de commande ${po.po_nbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    
    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 0px 0 0px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 10%;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:6%;
    }
    #items table th:nth-child(2) {
      text-align: left;
      width:10%;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 25%;
    }
    #items table th:nth-child(4) {
      text-align: right;
      width:10%;
    }

    #items table th:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table th:nth-child(6) {
      text-align: right;
      width:14%;
   
    }
    #items table th:nth-child(7) {
      text-align: right;
      width:10%;
   
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:17%;
   
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
      width:6%;
   
    }
    #items table td:nth-child(2) {
      text-align: left;
      width:10%;
   
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 25%;
    }
    #items table td:nth-child(4) {
      text-align: right;
      width:10%;
   
    }

    #items table td:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table td:nth-child(6) {
      text-align: right;
      width:14%;
   
    }
    #items table td:nth-child(7) {
      text-align: right;
      width:10%;
   
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:17%;
   
    }
   
    #items {
      margin: 5px 0 35px 0;
      font-size:8px
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 8px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 8px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 0px 0 0px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 10%;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:6%;
    }
    #items table th:nth-child(2) {
      text-align: left;
      width:10%;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 25%;
    }
    #items table th:nth-child(4) {
      text-align: right;
      width:10%;
    }

    #items table th:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table th:nth-child(6) {
      text-align: right;
      width:14%;
   
    }
    #items table th:nth-child(7) {
      text-align: right;
      width:10%;
   
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:17%;
   
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
      width:6%;
   
    }
    #items table td:nth-child(2) {
      text-align: left;
      width:10%;
   
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 25%;
    }
    #items table td:nth-child(4) {
      text-align: right;
      width:10%;
   
    }

    #items table td:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table td:nth-child(6) {
      text-align: right;
      width:14%;
   
    }
    #items table td:nth-child(7) {
      text-align: right;
      width:10%;
   
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:17%;
   
    }
   
    #items {
      margin: 10px 0 10px 0;
      font-size:10px
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

      <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          provider.vd_addr ? provider.vd_addr : ""
        }</span>
        <span class="bold-client">${
          provider.address.ad_name ? provider.address.ad_name : ""
        }</span>
        <span class="bold-client">${
          provider.address.ad_country ? provider.address.ad_country : ""
        }</span>

      </div>

      <div>
        <span>Telephone: ${
          provider.address.ad_phone ? provider.address.ad_phone : ""
        }     Fax: ${
    provider.address.ad_fax ? provider.address.ad_fax : ""
  }</span>
        <span>Devise: ${po.po_curr ? po.po_curr : ""} </span>
        <span>Mode de paiement: </span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de commande N: ${po.po_nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>QTE</th>
          <th>UM</th>
          <th>PU</th>
          <th>tva</th>
          <th>Montant HT</th>
        </tr>

        ${tableContent}
      </table>

    </section>
    <section id="sums">

    <table cellpadding="0" cellspacing="0">
      <tr>
        <th>TOTAL(HT)</th>
        <td>${total.toFixed(2)}</td>
      </tr>

      <tr>
        <th>TVA</th>
        <td>${tva.toFixed(2)}</td>
      </tr>
      <tr>
        <th>Timbre</th>
        <td>${timbre.toFixed(2)}</td>
      </tr>
      <tr>
        <th>TOTAL(TTC)</th>
        <td>${round(total + tva + timbre, 2).toFixed(2)}</td>
      </tr>
      
     


    </table>

  </section>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>
        <span class="bold">Arretée le present bon commande a la somme de : ${totalstring} </span>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printTag(data) {
  console.log("ss", data);
  let tableContent = ``;
  const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.length; i++) {
    let s = `
      <tr>
        <th>${data[i].tag.tag_part}</br>
        ${data[i].tag.tag_serial}</th>
        <th>${data[i].tag.tag_ref ? data[i].tag.tag_ref : ""}</th>
        <th>${data[i].tag.tag_site}</br>
        ${data[i].tag.tag_loc}</th>
        <th>${data[i].item.pt_desc1}</th>
        <th>${data[i].item.pt_abc}</th>
        <th></th>
        <th></th>
        <th>${data[i].item.pt_um}</th>
        <th>(_________)</th>
        <th>(_________)</th>
        <th>(_________)</th> 
      </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Inventaire </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Feuille d'inventaire tournant ${nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>ARTICLE</br>
          Lot/serie</th>
          <th>Ref</th>
          <th>Site</br>
          Emplacement</th>
          <th>Description</th>
          <th>ABC</th>
          <th>Dernier Cpt</th>
          <th>Qte En Stk</th>
          <th>UM</th>
          <th>Qte Comptee</th>
          <th>Compte par</th>
          <th>Date Compte</th>

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printReceive(provider, details, nbr,site) {
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  for (var i = 0; i < details.length; i++) {
    let s = `
      <tr>
          <td>${
            details[i].prh_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].prh_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].prh_rcvd}</td>
          <td>${details[i].prh_um}</td>
          <td>${site}</td>
          <td>${details[i].prh_loc}</td>
          <td>${details[i].prh_serial}</td>
          <td>${details[i].prh_vend_lot}</td>
          <td>${details[i].tr_expire ? details[i].tr_expire : ""}</td>

        </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de Reception ${nbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 0px 0 0px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 10%;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:5%;
    }
    #items table th:nth-child(2) {
      text-align: left;
      width:10%;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
      width:10%;
    }

    #items table th:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table th:nth-child(6) {
      text-align: right;
      width:8%;
   
    }
    #items table th:nth-child(7) {
      text-align: right;
      width:10%;
   
    }
    #items table th:nth-child(8) {
      text-align: right;
      width:17%;
   
    }
    #items table th:nth-child(9) {
      text-align: right;
      width:12%;
   
    }
    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:10%;
   
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
      width:5%;
   
    }
    #items table td:nth-child(2) {
      text-align: left;
      width:10%;
   
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
      width:10%;
   
    }

    #items table td:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table td:nth-child(6) {
      text-align: right;
      width:8%;
   
    }
    #items table td:nth-child(7) {
      text-align: right;
      width:10%;
   
    }
    #items table td:nth-child(8) {
      text-align: right;
      width:17%;
   
    }
    #items table td:nth-child(9) {
      text-align: right;
      width:12%;
   
    }
    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:10%;
  
    }
   
    #items {
      margin: 5px 0 5px 0;
      font-size:6px
    }
    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          provider.ad_addr ? provider.ad_addr : ""
        }</span>
        <span class="bold-client">${
          provider.ad_name ? provider.ad_name : ""
        }</span>
        <span class="bold-client">${
          provider.ad_country ? provider.ad_country : ""
        }</span>

      </div>

      <div>
        <span>Telephone: ${
          provider.ad_phone ? provider.ad_phone : ""
        }     Fax: ${provider.ad_fax ? provider.ad_fax : ""}</span>
        <span>Mode de paiement: </span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de reception N: ${nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>QTE</th>
          <th>UM</th>         
          <th>Site</th>
          <th>Empl</th>
          <th>Lot</th>
          <th>Lot Four</th>
          <th>Exp</th>

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printReceiveUNP(provider, details, nlot) {
  
  let adaddr = ""
  let adname = ""
  let adline = ""
  if (provider != null) { 
    adaddr = provider.ad_addr
  adname = provider.ad_name
  adline = provider.ad_line1}
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  for (var i = 0; i < details.length; i++) {
    let s = `
      <tr>
          <td>${
            details[i].tr_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].tr_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].tr_qty_loc}</td>
          <td>${details[i].tr_um}</td>
          <td>${details[i].tr_price}</td>
          <td>${details[i].tr_site}</td>
          <td>${details[i].tr_loc}</td>
          <td>${details[i].tr_serial}</td>
          <td>${details[i].tr_expire ? details[i].tr_expire : ""}</td>

        </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Reception Non planifiee N° ${nlot} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
      width: 8%;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
   width: 8%;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
    <div>
      <span class="bold-client">${
        adaddr
      }</span>
      <span class="bold-client">${
        adname
      }</span>
      <span class="bold-client">${
        adline
      }</span>

    </div>

  
  </section>

  
    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de reception Non planifiee N° ${nlot}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>QTE</th>
          <th>UM</th>
          <th>PU</th>
          <th>Site</th>
          <th>Empl</th>
          <th>Lot/Serie</th>
          <th>Expire</th>

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="client-info">
    <div>
      <span class="bold-client">${
        adaddr
      }</span>
      <span class="bold-client">${
        adname
      }</span>
      <span class="bold-client">${
        adline
      }</span>

    </div>

  
  </section>
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printTR(it, details, nlot) {
  let tableContent = ``;
  
  let total = 0;
  let tva = 0;
  for (var i = 0; i < details.length; i++) {
    let s = `
      <tr>
          <td>${
            details[i].tr_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].tr_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].tr_qty_loc}</td>
          <td>${details[i].tr_um}</td>
          <td>${details[i].tr_um_conv}</td>
          <td>${details[i].tr_serial}</td>
          <td>${details[i].tr_expire ? details[i].tr_expire : ""}</td>

        </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>bon de Tansfer ${nlot} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">Site : ${it.tr_site ? it.tr_site : ""}</span>
        <span class="bold-client">Empl : ${it.tr_loc ? it.tr_loc : ""}</span>
        <span class="bold-client">Site A :${
          it.tr_ref_site ? it.tr_ref_site : ""
        }</span>
        <span class="bold-client">Empl A :${
          it.tr_ref_loc ? it.tr_ref_loc : ""
        }</span>
        
      </div>

      <div>
        
      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de Transfer N° ${nlot} </span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Conv UM</th>
          <th>Lot/Serie</th>
          <th>Expire</th>

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printISSUNP(provider, details, nlot) {
  
  let adaddr = ""
  let adname = ""
  let adline = ""
  if (provider != null) { 
    adaddr = provider.ad_addr
  adname = provider.ad_name
  adline = provider.ad_line1}
   console.log(nlot);

  let tableContent = ``;
  let total = 0;
  let tva = 0;
  for (var i = 0; i < details.length; i++) {
    let s = `
      <tr>
          <td>${
            details[i].tr_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].tr_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].tr_qty_loc}</td>
          <td>${details[i].tr_um}</td>
          
          <td>${details[i].tr_site}</td>
          <td>${details[i].tr_loc}</td>
          <td>${details[i].tr_serial}</td>
          
          <td>${details[i].tr_expire ? details[i].tr_expire : ""}</td>

        </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Sortie Non planifiee N° ${nlot} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      font-size:10px;
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          adaddr
        }</span>
        <span class="bold-client">${
          adname
        }</span>
        <span class="bold-client">${
          adline
        }</span>

      </div>

    
    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de Sortie Non planifiee N° ${nlot}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Site</th>
          <th>Emplacement</th>
          <th>Lot/Serie</th>
          <th>Expire</th>

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printSO(customer, details, so) {
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  let timbre = 0;
  for (var i = 0; i < details.length; i++) {
    console.log(details[i].desc);
    let s = `
      <tr>
          <td>${
            details[i].sod_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].sod_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].sod_qty_ord}</td>
          <td>${details[i].sod_um}</td>
          <td>${details[i].sod_price}</td>
          <td>${details[i].sod_disc_pct}</td>
          <td>${details[i].sod_taxc}</td>
          <td>${
            details[i].sod_price *
            ((100 - details[i].sod_disc_pct) / 100) *
            details[i].sod_qty_ord
          }</td>
      </tr>
      `;
    total +=
      details[i].sod_price *
      ((100 - details[i].sod_disc_pct) / 100) *
      details[i].sod_qty_ord;
    if (so.so_taxable)
      tva +=
        details[i].sod_price *
        ((100 - details[i].sod_disc_pct) / 100) *
        details[i].sod_qty_ord *
        (details[i].sod_taxc ? details[i].sod_taxc / 100 : 0);

    tableContent = tableContent + s;
  }
  console.log(tva);
  if (so.so_cr_terms == "ES") {
    timbre = (total + tva) / 100;
    if (timbre > 10000) {
      timbre = 10000;
    }
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de commande ${so.so_nbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          customer.cm_addr ? customer.cm_addr : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_name ? customer.address.ad_name : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_line1 ? customer.address.ad_line1 : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_country ? customer.address.ad_country : ""
        }</span>

      </div>

      <div>
      <span>Matricule Fiscale: ${
        customer.address.ad_misc2_id ? customer.address.ad_misc2_id : ""
      }   </span>
      <span>RC               : ${
        customer.address.ad_gst_id ? customer.address.ad_gst_id : ""
      }       </span>
      <span>AI               : ${
        customer.address.ad_pst_id ? customer.address.ad_pst_id : ""
      }       </span>
      <span>NIS              : ${
        customer.address.ad_misc1_id ? customer.address.ad_misc1_id : ""
      }   </span>
      <span>Contact: </span>

    </div>


      <div>
        <span>Telephone: ${
          customer.address.ad_phone ? customer.address.ad_phone : ""
        }     Fax: ${
    customer.address.ad_fax ? customer.address.ad_fax : ""
  }</span>
        <span>Devise: ${so.so_curr ? so.so_curr : ""} </span>
        <span>Mode de paiement: ${so.so_cr_terms ? so.so_cr_terms : ""}  </span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Commande N: ${so.so_nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Prix Unitaire</th>
          <th>Remise</th>
          <th>tva</th>
          <th>Montant HT</th>
        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

      <table cellpadding="0" cellspacing="0">
        <tr>
          <th>TOTAL(HT)</th>
          <td>${total.toFixed(2)}</td>
        </tr>

        <tr>
          <th>TVA</th>
          <td>${tva.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Timbre</th>
          <td>${timbre.toFixed(2)}</td>
        </tr>
        <tr>
          <th>TOTAL(TTC)</th>
          <td>${(total + tva + timbre).toFixed(2)}</td>
        </tr>
        
       


      </table>

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>
        <span class="bold">Arretée le present bon commande a la somme de :${NumberToLetter(
          Number((total + tva + timbre).toFixed(2))
        )}  </span>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printOc(customer, details, qo) {
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  let timbre = 0;
  for (var i = 0; i < details.length; i++) {
    console.log(details[i].desc);
    let s = `
      <tr>
          <td>${
            details[i].qod_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].qod_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].qod_qty_ord}</td>
          <td>${details[i].qod_um}</td>
          <td>${details[i].qod_price}</td>
          <td>${details[i].qod_disc_pct}</td>
          <td>${details[i].qod_taxc}</td>
          <td>${
            details[i].qod_price *
            ((100 - details[i].qod_disc_pct) / 100) *
            details[i].qod_ord_qty
          }</td>
          </tr>
          `;
    total +=
      details[i].qod_price *
      ((100 - details[i].qod_disc_pct) / 100) *
      details[i].qod_ord_qty;
    if (qo.qo_taxable)
      tva +=
        details[i].qod_price *
        ((100 - details[i].qod_disc_pct) / 100) *
        details[i].qod_ord_qty *
        (details[i].qod_taxc ? details[i].qod_taxc / 100 : 0);

    tableContent = tableContent + s;
  }
  console.log(tva);
  if (qo.qo_cr_terms == "ES") {
    timbre = (total + tva) / 100;
  } else {
    timbre = 0;
  }
  if (timbre > 10000) {
    timbre = 10000;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de commande ${qo.qo_nbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

      <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          customer.cm_addr ? customer.cm_addr : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_name ? customer.address.ad_name : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_line1 ? customer.address.ad_line1 : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_country ? customer.address.ad_country : ""
        }</span>

      </div>

      <div>
      <span>Matricule Fiscale: ${
        customer.address.ad_misc2_id ? customer.address.ad_misc2_id : ""
      }   </span>
      <span>RC               : ${
        customer.address.ad_gst_id ? customer.address.ad_gst_id : ""
      }       </span>
      <span>AI               : ${
        customer.address.ad_pst_id ? customer.address.ad_pst_id : ""
      }       </span>
      <span>NIS              : ${
        customer.address.ad_misc1_id ? customer.address.ad_misc1_id : ""
      }   </span>
      <span>Contact: </span>

    </div>


      <div>
        <span>Telephone: ${
          customer.address.ad_phone ? customer.address.ad_phone : ""
        }     Fax: ${
    customer.address.ad_fax ? customer.address.ad_fax : ""
  }</span>
        <span>Devise: ${qo.qo_curr ? qo.qo_curr : ""} </span>
        <span>Mode de paiement: ${qo.qo_cr_terms ? qo.qo_cr_terms : ""}  </span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Offre Commerciale N: ${qo.qo_nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Prix Unitaire</th>
          <th>Remise</th>
          <th>tva</th>
          <th>Montant HT</th>
        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

      <table cellpadding="0" cellspacing="0">
        <tr>
          <th>TOTAL(HT)</th>
          <td>${total.toFixed(2)}</td>
        </tr>

        <tr>
          <th>TVA</th>
          <td>${tva.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Timbre</th>
          <td>${timbre.toFixed(2)}</td>
        </tr>
        <tr>
          <th>TOTAL(TTC)</th>
          <td>${(total + tva + timbre).toFixed(2)}</td>
        </tr>
        
       


      </table>

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>
        <span class="bold">Arretée le present bon commande a la somme de :${NumberToLetter(
          Number((total + tva + timbre).toFixed(2))
        )}  </span>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printBL(customer, details, pshnbr,cr_terms) {
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  let timbre = 0;
  for (var i = 0; i < details.length; i++) {
    console.log(details[i].desc);
    let s = `
      <tr>
          <td>${details[i].psh_line}</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].psh_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].psh_qty_ship}</td>
          <td>${details[i].psh_um}</td>
          <td>${details[i].psh_price}</td>
          <td>${details[i].psh_disc_pct}</td>
          <td>${details[i].psh_taxc}</td>
          <td>${
            details[i].psh_price *
            ((100 - details[i].psh_disc_pct) / 100) *
            details[i].sod_qty_ship
          }</td>

         
           </tr>
          `;
          total +=
          details[i].psh_price *
          ((100 - details[i].psh_disc_pct) / 100) *
          details[i].psh_qty_ship;
        if ([details[i].psh_taxable])
          tva +=
            details[i].psh_price *
            ((100 - details[i].psh_disc_pct) / 100) *
            details[i].psh_qty_ship *
            (details[i].psh_taxable ? details[i].psh_taxc / 100 : 0);
    
        tableContent = tableContent + s;
      }
      console.log(tva);
      if (cr_terms == "ES") {
        timbre = (total + tva) / 100;
        if (timbre > 10000) {
          timbre = 10000;
        }
      }
      let popupWin;
     
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de Livraison ${pshnbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

    <div class="logo">
    <img src="../../../../assets/media/companylogo.PNG" />
    </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          customer.cm_addr ? customer.cm_addr : ""
        }</span>
        <span class="bold-client">${
          customer.ad_name ? customer.ad_name : ""
        }</span>
        <span class="bold-client">${
          customer.ad_line1 ? customer.ad_line1 : ""
        }</span>
        <span class="bold-client">${
          customer.ad_country ? customer.ad_country : ""
        }</span>

      </div>

      <div>
      <span>Matricule Fiscale: ${
        customer.ad_misc2_id ? customer.ad_misc2_id : ""
      }   </span>
      <span>RC               : ${
        customer.ad_gst_id ? customer.ad_gst_id : ""
      }       </span>
      <span>AI               : ${
        customer.ad_pst_id ? customer.ad_pst_id : ""
      }       </span>
      <span>NIS              : ${
        customer.ad_misc1_id ? customer.ad_misc1_id : ""
      }   </span>
      <span>Contact: </span>

    </div>


      <div>
        <span>Telephone: ${
          customer.ad_phone ? customer.ad_phone : ""
        }     Fax: ${customer.ad_fax ? customer.ad_fax : ""}</span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Bon de Livraison N: ${pshnbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Prix Unitaire</th>
         </tr>

        ${tableContent}
      </table>

    </section>
    <section id="sums">

    <table cellpadding="0" cellspacing="0">
      <tr>
        <th>TOTAL(HT)</th>
        <td>${total.toFixed(2)}</td>
      </tr>

      <tr>
        <th>TVA</th>
        <td>${tva.toFixed(2)}</td>
      </tr>
      <tr>
        <th>Timbre</th>
        <td>${timbre.toFixed(2)}</td>
      </tr>
      <tr>
        <th>TOTAL(TTC)</th>
        <td>${(total + tva + timbre).toFixed(2)}</td>
      </tr>
      
     


    </table>

  </section>

  <div class="clearfix"></div>
  <div class="clearfix"></div>
  <br>
  <br>
  <br>

  <section id="client-info">
    <div>
      <span class="bold">Arretée le present bon commande a la somme de :${NumberToLetter(
        Number((total + tva + timbre).toFixed(2))
      )}  </span>

    <div>

  </section>
  

</div>



</body>

</html>
    `);
  popupWin.document.close();
}

export function printIH(customer, details, ih, curr) {
  let tableContent = ``;
  let total = 0;
  let tva = 0;
  let timbre = 0;
  for (var i = 0; i < details.length; i++) {
    console.log(details[i].desc);
    let s = `
      <tr>
          <td>${
            details[i].itdh_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].itdh_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].itdh_qty_inv}</td>
          <td>${details[i].itdh_um}</td>
          <td>${details[i].itdh_price}</td>
          <td>${details[i].itdh_disc_pct}</td>
          <td>${details[i].itdh_taxc}</td>
          <td>${
            details[i].itdh_price *
            ((100 - details[i].itdh_disc_pct) / 100) *
            details[i].itdh_qty_inv
          }</td>
          </tr>
          `;
    total += round(
      details[i].itdh_price *
        ((100 - details[i].itdh_disc_pct) / 100) *
        details[i].itdh_qty_inv,
      2
    );
    if (details[i].itdh_taxable)
      tva += round(
        details[i].itdh_price *
          ((100 - details[i].itdh_disc_pct) / 100) *
          details[i].itdh_qty_inv *
          (details[i].itdh_taxc ? details[i].itdh_taxc / 100 : 0),
        2
      );

    tableContent = tableContent + s;
  }
  console.log(tva);
  if (ih.ith_cr_terms == "ES") {
    timbre = (total + tva) / 100;
  } else {
    timbre = 0;
  }
  if (timbre > 10000) {
    timbre = 10000;
  }
  let popupWin;
  const date = new Date();
  let totalstring = replace(
    NumberToLetter(Number((total + tva + timbre).toFixed(2))),
    "Dinars Algérien",
    curr.cu_desc
  );
  console.log(totalstring);
  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Bon de commande ${ih.ith_inv_nbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }
    #fiscal-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: right;
    }

    #fiscal-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #fiscal-info span {
      display: block;
      min-width: 20px;
    }
    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 18px;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 30%;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

      <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>


    <section id="client-info">
      <div>
        <span class="bold-client">${
          customer.cm_addr ? customer.cm_addr : ""
        }  </span>
        <span class="bold-client">${
          customer.address.ad_name ? customer.address.ad_name : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_line1 ? customer.address.ad_line1 : ""
        }</span>
        <span class="bold-client">${
          customer.address.ad_country ? customer.address.ad_country : ""
        }</span>

      </div>

      <div>
        <span>     Fax: ${
          customer.address.ad_fax ? customer.address.ad_fax : ""
        }</span>
        <span>Devise: ${ih.ith_curr ? ih.ith_curr : ""} </span>
        <span>Mode de paiement: ${ih.ith_cr_terms ? ih.ith_cr_terms : ""}  </span>
        <span>Mode de livraison: </span>
        <span>Contact: </span>

      </div>

    </section>
    <section id="fiscal-info">
    <div>
      <span class="bold-client">MF  : ${
        customer.address.ad_misc2_id ? customer.address.ad_misc2_id : ""
      } </span>
      <span class="bold-client">RC  : ${
        customer.address.ad_gst_id ? customer.address.ad_gst_id : ""
      }       </span>
      <span class="bold-client">AI  : ${
        customer.address.ad_pst_id ? customer.address.ad_pst_id : ""
      }  </span>
      <span class="bold-client">NIS : ${
        customer.address.ad_misc1_id ? customer.address.ad_misc1_id : ""
      }   </span>

    </div>

    
  </section>

    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Facture N° : ${ih.ith_inv_nbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>UM</th>
          <th>Prix Unitaire</th>
          <th>Remise</th>
          <th>tva</th>
          <th>Montant HT</th>
        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

      <table cellpadding="0" cellspacing="0">
        <tr>
          <th>TOTAL(HT)</th>
          <td>${total.toFixed(2)}</td>
        </tr>

        <tr>
          <th>TVA</th>
          <td>${tva.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Timbre</th>
          <td>${timbre.toFixed(2)}</td>
        </tr>
        <tr>
          <th>TOTAL(TTC)</th>
          <td>${round(total + tva + timbre, 2).toFixed(2)}</td>
        </tr>
        
       


      </table>

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    
    <section id="client-info">
      <div>
    
        <span class="bold">Arretée le presente bon commande a la somme de :${totalstring}  </span>

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printInventory(data,date) {
  console.log("ss", data);
  let tableContent = ``;
  for (var i = 0; i < data.length; i++) {
    let s = `
      <tr>
        <th>${data[i].ld_part}</th>
        <th>${data[i].ld_site}</th>

        <th>${data[i].ld_loc}</th>
        <th>${data[i].item.pt_desc1}</th>
        <th>${data[i].total_qty}</th>
       
      </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Etat Stock </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Inventaire a la date ${date}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>ARTICLE</th>
          <th>Site</th>
          <th>Emplacement</th>
          <th>Description</th>
         
          <th>Qte En Stk</th>
          

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printInventoryOfSecurity(det) {
  console.log("ss", det);
  let tableContent = ``;
  for (var i = 0; i < det.length; i++) {
    let s = `
      <tr>
        <th>${det[i].ld_part}</th>
        <th>${det[i].ld_site}</th>

        <th>${det[i].ld_loc}</th>
        <th>${det[i].item.pt_desc1}</th>
        <th>${det[i].qty}</th>
        <th>${det[i].item.pt_safety_stk}</th>
       
      </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Etat Stock sous Min</title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Etats des stocks sous Stock de sécurité </span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>ARTICLE</th>
          <th>Site</th>
          <th>Emplacement</th>
          <th>Description</th>
         
          <th>Qte En Stk</th>
          <th>Seuil de Sécurité</th>
          

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printInventoryActivity(data) {
  console.log("ss", data);
  let tableContent = ``;
  for (var i = 0; i < data.length; i++) {
    let s = `
      <tr>
        <th>${data[i].ld_part}      </th>
        <th>${data[i].item.pt_desc1}</th>
        <th>${data[i].total_qty_1}  </th>
        <th>${data[i].total_qty_tagcnt}</th>
        <th>${data[i].total_qty_rctwo}</th>
        <th>${data[i].total_qty_rctpo}</th>
        <th>${data[i].total_qty_rcttr}</th>
        <th>${data[i].total_qty_rctcns}</th>
        <th>${data[i].total_qty_rctchl}</th>
        <th>${data[i].total_qty_rctunp}</th>
        <th>${data[i].total_qty_issprv}</th>
        <th>${data[i].total_qty_isswo}</th>
        <th>${data[i].total_qty_issso}</th>
        <th>${data[i].total_qty_isscns}</th>
        <th>${data[i].total_qty_issunp}</th>
        <th>${data[i].total_qty_2}</th>
       
      </tr>
      `;

    tableContent = tableContent + s;
  }
  let popupWin;

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Journal Stock </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Journal des stocks</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>Stock initial</th>
          <th>Inventaire</th>
          <th>Production</th>
          <th>Achat</th>
          <th>transfert</th>
          <th>Réintegration hors prod</th>
          <th>Rebut</th>
          <th>Autres réceptions</th>
          <th>Retour Fournisseur</th>
          <th>Consommation</th>
          <th>Vente</th>
          <th>Sortie hors prod</th>
          <th>Autres sorties</th>
          <th>Stock Fin</th>
          

        </tr>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printInventoryByStatus(data,date) {
  console.log("ss", data.results_head);
  console.log("sss", data.results_body);

  let tableContent = ``;
  let addr_head = ``;
  let addr_body = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    addr_head == data.results_head[i].ld_loc_head;
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].ld_status_head}</th>
      <th>${data.results_head[i].ld_loc_head}</th>
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    var j = 0; 
    let total_oh = 0;
    
    do {
      if(data.results_head[i].ld_status_head == data.results_body[j].ld_status_body,data.results_head[i].ld_loc_head == data.results_body[j].ld_loc_body ){
    let b = `
    <tbody>
    <tr>  
      <th></th>
      <th></th>  
      <th>${data.results_body[j].ld_part}     </th> 
      <th>${data.results_body[j].pt_desc1}    </th>
      <th>${data.results_body[j].pt_um}       </th>
      <th>${data.results_body[j].ld_lot}      </th>
      <th>${data.results_body[j].ld_qty_oh}   </th> 
      <th>${data.results_body[i].ld_expire}   </th>
    </tr>
    </tbody>
    `;
    
    total_oh  = total_oh  + Number(data.results_body[j].ld_qty_oh);
    tableContent = tableContent + b;}
      j = j + 1;
    }while( j < data.results_body.length )
    let t = 
    `
    <tbody>
    <tr>  
      <th></th>  
      <th></th>
      <th></th>
      <th></th>
      <th>Total Emplacement</th>
      <th>${total_oh}    </th>
      <th></th> 
    </tr>
    </tbody>
    `;
    tableContent = tableContent + t;
  } let popupWin;

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Etat Stock par statut</title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Etat Stock par Statut</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <th>Statut</th>
          <th>Emplacement</th>
          <th>Article</th>
          <th>Désignation</th>
          <th>UM</th>
          <th>Lot N°</th>
          <th>Qte En Stk</th>
          <th>Expire le </th>
          

        </tr>
      </thead>
        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printInventoryByLoc(data) {
  console.log("ss", data.results_head);
  console.log("sss", data.results_body);

  let tableContent = ``;
  let addr_head = ``;
  let addr_body = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    addr_head == data.results_head[i].ld_loc_head;
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].ld_loc_head}     </th>
          
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    var j = 0; 
    let total_oh = 0;
    
    do {
      if(data.results_head[i].ld_loc_head == data.results_body[j].ld_loc_body){
    let b = `
    <tbody>
    <tr>  
      <th></th>  
      <th>${data.results_body[j].ld_part}     </th> 
      <th>${data.results_body[j].pt_desc1}    </th>
      <th>${data.results_body[j].pt_um}       </th>
      <th>${data.results_body[j].ld_status}   </th>
      <th>${data.results_body[j].ld_lot}      </th>
      <th>${data.results_body[j].ld_qty_oh}   </th> 
    </tr>
    </tbody>
    `;
    
    total_oh  = total_oh  + Number(data.results_body[j].ld_qty_oh);
    tableContent = tableContent + b;}
      j = j + 1;
    }while( j < data.results_body.length )
    let t = 
    `
    <tbody>
    <tr>  
      <th></th>  
      <th></th>
      <th></th>
      <th></th>
      <th>Total Emplacement    </th>
      <th>${total_oh}</th> 
    </tr>
    </tbody>
    `;
    tableContent = tableContent + t;
  }
  let popupWin;

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Etat Stock par emplacement</title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Stock par Emplacement</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <th>Emplacement</th>
          <th>Article</th>
          <th>Désignation</th>
          <th>UM</th>
          <th>Statut</th>
          <th>Lot N°</th>
          <th>Qte En Stk</th>
          

        </tr>
      </thead>
        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printItemPurchaseByProvider(data) {
  console.log("ss", data.results_head);
  console.log("sss", data.results_body);

  let tableContent = ``;
  let addr_head = ``;
  let addr_body = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    addr_head == data.results_head[i].vd_addr_head;
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].vd_addr_head}     </th>
      
      <th>${data.results_head[i].vd_sort_head}     </th>
      
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    var j = 0; 
    let total_ord = 0;
    let val_ord   = 0;
    let total_rec = 0;
    let val_rec   = 0;

    do {
      if(data.results_head[i].vd_addr_head == data.results_body[j].vd_addr_body){
    let b = `
    <tbody>
    <tr>  
      <th></th>  
      <th></th>  
      <th>${data.results_body[j].pod_part}    </th> 
      <th>${data.results_body[j].pt_desc1}    </th>
      <th>${data.results_body[j].po_nbr}      </th>
      <th>${data.results_body[j].pod_line}    </th>
      <th>${data.results_body[j].pod_um}      </th>
      <th>${data.results_body[j].pod_qty_ord} </th>
      <th>${data.results_body[j].pod_price}   </th>
      <th>${data.results_body[j].pod_qty_rcvd}</th> 
    </tr>
    </tbody>
    `;
    
    total_ord = total_ord + Number(data.results_body[j].pod_qty_ord);
    val_ord   = val_ord   + Number(data.results_body[j].pod_qty_ord)* Number(data.results_body[j].pod_price);
    total_rec = total_rec + Number(data.results_body[j].pod_qty_rcvd);
    val_rec   = val_rec   + Number(data.results_body[j].pod_qty_rcvd)* Number(data.results_body[j].pod_price); 
      tableContent = tableContent + b;}
      j = j + 1;
    }while( j < data.results_body.length )
    let t = 
    `
    <tbody>
    <tr>  
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>TOTAL Commande</th>
      <th>${total_ord}  </br>
          ${val_ord}    </th>
      <th>Total Reçu    </th>
      <th>${total_rec}</br> 
          ${val_rec}    </th>
    </tr>
    </tbody>
    `;
    tableContent = tableContent + t;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>Articles par fournisseur </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 0,1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: right;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:20%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      

    }
    #items table th:nth-child(2) {
      text-align: left;
      
    }
    #items table th:nth-child(3) {
      text-align: right;
     
    }
    #items table th:nth-child(4) {
      text-align: right;
     
    }

    #items table th:nth-child(5) {
      text-align: right;
      
    }

    #items table th:nth-child(6) {
      text-align: right;
     
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: left;
    }

    #items table td:nth-child(5) {
      text-align: left;
    }

    #items table td:nth-child(6) {
      text-align: left;
      width:8%
    }

    #items table td:last-child {
      text-align: left;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 10 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 21px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
     

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: left;
    }

    #items table th:nth-child(5) {
      text-align: left;
    }

    
    #items table th:nth-child(6) {
      text-align: left;
     
    }

    #items table th:last-child {
      text-align: left;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: left;
    }

    #items table td:nth-child(5) {
      text-align: left;
    }
    
    #items table td:nth-child(6) {
      text-align: left;
      width:8%
    }

    #items table td:last-child {
      text-align: left;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 10 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Liste des Articles par Fournisseur</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Fournisseur</th>
          <th>Nom</th>
          <th>Code article</th>
          <th>Description</th>
          <th>Ordre</th>
          <th>Ligne</th>
          <th>UM</th>
          <th>Qte Commandée</th>
          <th>Prix</th>
          <th>Qte Réceptionnée</th>
          
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printCustomerActivity(data) {
  console.log("ss", data);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data[i].cm_addr_head}     </th>
      <th>${data[i].cm_sort_head}     </th>
      <th>${data[i].cm_ord_amt}       </th>
      <th>${data[i].cm_ship_amt}      </th>
      <th>${data[i].cm_inv_amt}       </th>
      <th>${data[i].cm_paid_amt}      </th>    
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
  }
    let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Activité Client </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Activité Clients</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Client</th>
          <th>Nom</th>
          <th>Montant Commandé</th>
          <th>Montant Livré</th>
          <th>Montant Facturé</th>
          <th>Montant Payé</th>
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printProviderActivity(data) {
  console.log("ss", data.results_head);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].vd_addr_head}     </th>
      <th>${data.results_head[i].vd_sort_head}     </th>
      <th>${data.results_head[i].vd_ord_amt}       </th>
      <th>${data.results_head[i].vd_ship_amt}      </th>
      <th>${data.results_head[i].vd_inv_amt}       </th>
      <th>${data.results_head[i].vd_paid_amt}      </th>    
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
  }
    let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Activité Fournisseur </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Activité Fournisseurs</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Fournisseur</th>
          <th>Nom</th>
          <th>Montant Commandé</th>
          <th>Montant Réceptionné</th>
          <th>Montant Facturé</th>
          <th>Montant Payé</th>
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}

export function printProviderCA(data) {
  console.log("ss", data);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data[i].vd_addr_head}     </th>
      <th>${data[i].vd_sort_head}     </th>
      <th>${data[i].vd_ht_amt}     </th>
      <th>${data[i].vd_tva_amt}     </th>
      <th>${data[i].vd_tf_amt}     </th>
      <th>${data[i].vd_ttc_amt}     </th>    
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Activité Fournisseur </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Activité Fournisseur </span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Fournisseur</th>
          <th>Nom</th>
          <th>Montant HT</th>
          <th>Montant TVA</th>
          <th>Montant Timbre</th>
          <th>Montant TTC</th>
          
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printProviderBalance(data) {
  console.log("ss", data.results_head);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].vd_addr_head}     </th>
      <th>${data.results_head[i].vd_sort_head}     </th>
      <th>${data.results_head[i].vd_ord_amt}       </th>
      <th>${data.results_head[i].vd_ship_amt}      </th>
      <th>${data.results_head[i].vd_inv_amt}       </th>
      <th>${data.results_head[i].vd_paid_amt}      </th>    
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
  }
    let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Activité Fournisseur </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Activité Fournisseurs</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Fournisseur</th>
          <th>Nom</th>
          <th>Montant Commandé</th>
          <th>Montant Réceptionné</th>
          <th>Montant Facturé</th>
          <th>Montant Payé</th>
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}



export function printItemSalesByCustomer(data) {
  console.log("ss", data.results_head);
  console.log("sss", data.results_body);

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.results_head.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data.results_head[i].cm_addr_head}     </th>
      <th>    ${data.results_head[i].cm_sort_head}     </th>
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    var j = 0; 
    let total_ord = 0;
    let total_rec = 0;
    do {
    j = j + 1;   
    let b = `
    <tbody>
    <tr>  
      <th></th>  
      <th>${data.results_body[j].sod_part}    </th> 
      <th>    ${data.results_body[j].pt_desc1}    </th>
      <th>${data.results_body[j].so_nbr}      </th>
      <th>    ${data.results_body[j].sod_line}    </th>
      <th>${data.results_body[j].sod_um}      </th>
      <th>${data.results_body[j].sod_qty_ord} </th>
      <th>${data.results_body[j].sod_price}   </th>
      <th>${data.results_body[j].sod_qty_ship}</th> 
    </tr>
    </tbody>
    `;
    
    total_ord = total_ord + Number(data.results_body[j].sod_qty_ord); 
    total_rec = total_rec + Number(data.results_body[j].sod_qty_ship); 
      tableContent = tableContent + b;
    
    }while( data.results_body[j].cm_addr_body == data.results_head[i].cm_addr_head)
    let t = 
    `
    <tbody>
    <tr>  
      <th></th>  
      <th></th>
      <th>  </th>
      <th>  </th>
      <th>TOTAL Commande      </th>
      <th>${total_ord} </th>
      <th>Total Reçu   </th>
      <th>${total_rec}</th> 
    </tr>
    </tbody>
    `;
    tableContent = tableContent + t;
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Articles par Client </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Liste des Articles par Client</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Client</th>
          <th>Nom</th>
          <th>Code article</th>
          <th>Description</th>
          <th>Ordre</th>
          <th>Ligne</th>
          <th>UM</th>
          <th>Qte Commandée</th>
          <th>Prix</th>
          <th>Qte Livrée</th>

        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printCustomerSolde(data) {
  console.log("ss", data);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data[i].cm_addr_head}     </th>
      <th>    ${data[i].cm_sort_head}     </th>
      <th>${data[i].cm_balance}     </th>
      <th>${data[i].cm_ship_balance}     </th>   

    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    
  }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> Solde Client </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Etat Solde Par Client</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Client</th>
          <th>Nom</th>
          <th>Solde </th>
          
          <th>Solde BL</th>
          
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
export function printCustomerCAList(data) {
  console.log("ss", data);
  

  let tableContent = ``;
  //const nbr = data[0].tag.tag_nbr;
  for (var i = 0; i < data.length; i++) {
    let h = `
    <thead>
    <tr>
      <th>${data[i].cm_addr_head}     </th>
      <th>${data[i].cm_sort_head}     </th>
      <th>${data[i].cm_ht_amt}     </th>
      <th>${data[i].cm_tva_amt}     </th>
      <th>${data[i].cm_tf_amt}     </th>
      <th>${data[i].cm_ttc_amt}     </th>    
    </tr>
    </thead>
    `;
    tableContent = tableContent + h;
    
    
    
      }
  let popupWin;
  const date = new Date();

  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title> CA par Client </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }

    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 paysage; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 150px;
      height: 0;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 20px 0 35px 0;
      font-size:8px
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      text-align: left;
      width:24%
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
 
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:8%

    }
    #items table th:nth-child(2) {
      text-align: left;
    }
    #items table th:nth-child(3) {
      text-align: left;
    }
    #items table th:nth-child(4) {
      text-align: right;
    }

    #items table th:nth-child(5) {
      text-align: right;
    }

    
    #items table th:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
    }

    #items table td {
      padding: 15px 10px;

    }
    #items table td:nth-child(1) {
      text-align: left;
      width:8%
    }
    #items table td:nth-child(2) {
      text-align: left;
    }
    #items table td:nth-child(3) {
      text-align: left;
    }
    #items table td:nth-child(4) {
      text-align: right;
    }

    #items table td:nth-child(5) {
      text-align: right;
    }
    
    #items table td:nth-child(6) {
      text-align: right;
      width:8%
    }

    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
    }
    #items table td:first-child {
      text-align: left;
      border-right: 0 !important;
    }

    #items table td:nth-child(2) {
      text-align: left;
    }

    #items table td:last-child {
      border-right: 0 !important;
      padding-right: 20px !important;
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 16px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
  <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">Chiffre d'affaire par Client </span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th>Client</th>
          <th>Nom</th>
          <th>Montant HT</th>
          <th>Montant TVA</th>
          <th>Montant Timbre</th>
          <th>Montant TTC</th>
          
        </tr>
        </thead>

        ${tableContent}
      </table>

    </section>

   
    <section id="sums">

     

    </section>

    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

   
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}



/*print lp*/
export function printLp(details, lpnbr,wodnbr,wodlot,part,descr,routing,gamme,qte) {
  let tableContent = ``;
   for (var i = 0; i < details.length; i++) {
   
   
    let s = `
      <tr>
          <td>${
            details[i].wod_line
          }</td> <!-- Don't remove this column as it's needed for the row commands -->
          <td>${details[i].wod_part}</td>
          <td>${details[i].desc}</td>
          <td>${details[i].wod_qty_req}</td>
          <td>${details[i].wod_um}</td>
          <td>${details[i].wod_site}</td>
          <td>${details[i].wod_loc}</td>
          <td>${details[i].wod_serial}</td>
          <td>${details[i].wod_ref}</td>
        </tr>
      `;
  
    tableContent = tableContent + s;
  }
  ;
  let popupWin;
  
  const date = new Date();
  popupWin = window.open("");
  popupWin.document.open();
  popupWin.document.write(`
    <html lang="en">

<head>
  <title>List de Prélèvement ${lpnbr} </title>
  <style>
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    
    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 0px 0 0px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 5%;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:6%;
    }
    #items table th:nth-child(2) {
      text-align: left;
      width:10%;
    }
    #items table th:nth-child(3) {
      text-align: left;
      width: 25%;
    }
    #items table th:nth-child(4) {
      text-align: right;
      width:10%;
    }

    #items table th:nth-child(5) {
      text-align: right;
      width:8%;
   
    }
    #items table th:nth-child(6) {
      text-align: right;
      width:14%;
   
    }
    #items table th:nth-child(7) {
      text-align: right;
      width:10%;
   
    }

    #items table th:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:17%;
   
    }

    #items table td {
      padding: 10px 10px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: left;
      width:3%;
   
    }
    #items table td:nth-child(2) {
      text-align: left;
      width:12%;
   
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 20%;
    }
    #items table td:nth-child(4) {
      text-align: right;
      width:5%;
   
    }

    #items table td:nth-child(5) {
      text-align: right;
      width:5%;
   
    }
    #items table td:nth-child(6) {
      text-align: right;
      width:10%;
   
    }
    #items table td:nth-child(7) {
      text-align: right;
      width:25%;
   
    }
    #items table th:nth-child(8) {
      text-align: right;
      width:12%;
   
    }
    #items table td:last-child {
      text-align: right;
      padding-right: 20px !important;
      width:8%;
   
    }
   
    #items {
      margin: 10px 0 10px 0;
      font-size:12px
    }

    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 5px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 8px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
    @media print {
      @page {size: A4 portrait; }
      .pagebreak {
        clear: both;
        page-break-after: always;
    }s
      @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
    }

    html {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    q,
    blockquote {
      quotes: none;
    }

    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
      content: "";
      content: none;
    }

    a img {
      border: none;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    /* Invoice styles */
    /**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the qo
 */
    html,
    body {
      /* MOVE ALONG, NOTHING TO CHANGE HERE! */
    }

    /** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
    .clearfix {
      display: block;
      clear: both;
    }

    .hidden {
      display: none;
    }

    b,
    strong,
    .bold {
      font-weight: bold;
    }

    .bold-client {
      font-weight: bold;
      font-size: 20px;
    }
    #container {
      font: normal 13px/1.4em 'Open Sans', Sans-serif;
      margin: 0 auto;
      padding: 50px 40px;
      min-height: 1058px;
      padding-bottom: 9 rem;
    }

    #memo .company-name {
      background: #8BA09E url("../img/arrows.png") 560px center no-repeat;
      background-size: 100px auto;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 15px;
    }

    #memo .company-name span {
      color: white;
      display: inline-block;
      min-width: 20px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1em;
    }

    #memo .company-name .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: url("../img/right-arrow.png") right center no-repeat;
      background-size: auto 60px;
    }

    #memo .logo {
      float: left;
      clear: left;
      margin-left: 20px;
    }

    #memo .logo img {
      width: 95%;
      height: 120px;
    }

    #memo .company-info {
      margin-left: 20px;
      float: left;
      font-size: 12px;
      color: #8b8b8b;
    }

    #memo .company-info div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #memo .company-info span {
      display: inline-block;
      min-width: 20px;
    }

    #memo:after {
      content: '';
      display: block;
      clear: both;
    }

    #invoice-info {
      float: right;
      margin: 25px 0 0 20px;
    }

    #invoice-info>div {
      float: right;
    }

    #invoice-info>div>span {
      display: block;
      min-width: 20px;
      min-height: 18px;
      margin-bottom: 3px;
    }

    #invoice-info>div:last-child {
      margin-left: 20px;
    }

    #invoice-info:after {
      content: '';
      display: block;
      clear: both;
    }

    #client-info {
      float: left;
      margin: 5px 20px 0 0;
      min-width: 220px;
      text-align: left;
    }

    #client-info>div {
      margin-bottom: 3px;
      min-width: 20px;
    }

    #client-info span {
      display: block;
      min-width: 20px;
    }

    #invoice-title-number {
      text-align: center;
      margin: 20px 0;
    }

    #invoice-title-number span {
      display: inline-block;
      min-width: 20px;
    }

    #invoice-title-number #title {
      margin-right: 15px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    #invoice-title-number #number {
      font-size: 15px;
      text-align: left;
    }

    table {
      table-layout: fixed;
    }

    table th,
    table td {
      vertical-align: top;
      word-break: keep-all;
      word-wrap: break-word;
    }

    #items {
      margin: 0px 0 0px 0;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
      width: 10%;
      text-align: left;
    }

    #items table {
      border-collapse: separate;
      width: 100%;
    }

    #items table th {
      padding: 12px 10px;
      background: #E6E7E7;
      border-bottom: 4px solid #487774;
    }
    #items table th:nth-child(1) {
      text-align: left;
      width:7%;
    }
    #items table th:nth-child(2) {
      text-align: center;
      width:13%;
    }
    #items table th:nth-child(3) {
      text-align: center;
      width: 22%;
    }
    #items table th:nth-child(4) {
      text-align: center;
      width:10%;
    }

    #items table th:nth-child(5) {
      text-align: center;
      width:7%;
   
    }
    #items table th:nth-child(6) {
      text-align: center;
      width:10%;
   
    }
    #items table th:nth-child(7) {
      text-align: center;
      width:12%;
   
    }

    #items table th:nth-child(8) {
      text-align: center;
      width:10%;
   
    }

    #items table th:last-child {
      text-align: center;
      padding-right: 5px !important;
      width:10%;
   
    }

    #items table td {
      padding: 5px 5px;
      border-right: 1px solid #CCCCCF;
    }
    #items table td:nth-child(1) {
      text-align: center;
      width:7%;
   
    }
    #items table td:nth-child(2) {
      text-align: center;
      width:13%;
   
    }
    #items table td:nth-child(3) {
      text-align: left;
      width: 22%;
    }
    #items table td:nth-child(4) {
      text-align: right;
      width:10%;
   
    }

    #items table td:nth-child(5) {
      text-align: center;
      width:7%;
   
    }
    #items table td:nth-child(6) {
      text-align: center;
      width:10%;
   
    }
    #items table td:nth-child(7) {
      text-align: center;
      width:12%;
   
    }
    #items table th:nth-child(8) {
      text-align: center;
      width:10%;
   
    }
    #items table td:last-child {
      text-align: center;
      padding-right: 5px !important;
      width:10%;
   
    }
   
    #items {
      margin: 0px 0 0px 0;
      font-size:12px
    }


    .currency {
      border-bottom: 4px solid #487774;
      padding: 0 20px;
    }

    .currency span {
      font-size: 11px;
      font-style: italic;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    #sums {
      float: right;
      background: #8BA09E url("../img/left-arrow.png") -17px bottom no-repeat;
      background-size: auto 100px;
      color: white;
    }

    #sums table tr th,
    #sums table tr td {
      min-width: 100px;
      padding: 8px 20px 8px 35px;
      text-align: right;
      font-weight: 600;
    }

    #sums table tr th {
      text-align: left;
      padding-right: 25px;
    }

    #sums table tr.amount-total th {
      text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
      font-size: 16px;
      font-weight: bold;
    }

    #sums table tr:last-child th {
      text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
      font-size: 8px;
      font-weight: bold;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
    }

    #terms {
      margin: 50px 20px 10px 20px;
    }

    #terms>span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    #terms>div {
      margin-top: 10px;
      min-height: 50px;
      min-width: 50px;
    }

    .payment-info {
      margin: 0 20px;
    }

    .payment-info span {
      display: inline-block;
      min-width: 20px;
      font-weight: bold;
    }

    .payment-info div {
      font-size: 12px;
      color: #8b8b8b;
      display: inline-block;
      min-width: 20px;
    }

    .ib_bottom_row_commands {
      margin: 10px 0 0 20px !important;
    }

    .ibcl_tax_value:before {
      color: white !important;
    }
    #footer {
      position: absolute;
      bottom: 1;
      width: 100%;
      height: 8 rem;            /* Footer height */
    }
    hr {
      border: 3px solid #8BA09E;
      }
  }

</style>
</head>

<body >
  <div id="container">
    <section id="memo">

      <div class="logo">
      <img src="../../../../assets/media/companylogo.PNG" />
      </div>

      <div class="company-info">

      </div>

    </section>

    <div class="clearfix"></div>
    <br>




    <section id="client-info">
    
    <div>
      <span>Id OFf         : ${wodlot}</span>
      <span> N° OF         : ${wodnbr}</span>
      <span>Article        : ${part} </span>
      <span>Designation    : ${descr} </span>
      <span>Nomenclature   : ${routing} </span>
      <span>Gamme          : ${gamme} </span>
      <span>Qauntité Lancée: ${qte} </span>
    </div>

  </section>




    
    <section id="invoice-info">
    <div>
      <span class="bold-client">LE: ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}</span>

    </div>

  </section>
  <br>
  <br>
  <br>    
  <br>
  <br>
  <br>
  <br>
  <br>
    <div class="clearfix"></div>

    <section id="invoice-title-number">

      <span id="title">List de Prélèvement N: ${lpnbr}</span>
      <span id="number"></span>

    </section>

    <div class="clearfix"></div>

    <section id="items">

      <table cellpadding="0" cellspacing="0">

        <tr>
          <th>Ln</th> <!-- Dummy cell for the row number and row commands -->
          <th>ARTICLE</th>
          <th>Désignation</th>
          <th>QTE</th>
          <th>UM</th>
          <th>Site</th>
          <th>Empl</th>
          <th>Lot</th>
          <th>Ref</th>
        </tr>

        ${tableContent}
      </table>

    </section>
    <section id="sums">

    
  </section>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <br>
    <br>
    <br>

    <section id="client-info">
      <div>
        

      <div>

    </section>
    

  </div>


</body>

</html>
    `);
  popupWin.document.close();
}
