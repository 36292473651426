<kt-portlet>
    <kt-portlet-header [sticky]="true" [title]="'Pointages des Employés'" [class]="'card-head-lg'" [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <button type="button" (click)="goBack()" class="btn btn-secondary btn-sm mr-2">Annuller</button>
            <button type="button" (click)="reset()" class="btn btn-secondary btn-sm mr-2">Réinitialiser</button>
            <button type="button" (click)="onSubmit()" class="btn btn-success btn-sm mr-2">sauvegarder </button>


        </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
        <div class="example-preview">
            <form class="kt-form" [formGroup]="repForm">
                <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                (close)="onAlertClose($event)">
                {{message}}
            </kt-alert>

                <div class="row">
                    
                    
                    <div class="form-group col-2">
                        <label class="form-group">Fournisseur: </label>
                    
                        <div class="input-group input-group-lg">

                            <input type="text" class="form-control form-control-solid" formControlName="four" (change)="getRep()"
                            aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="open(content)">
                                    <i class="text-dark-50 flaticon2-search"></i> </span>
                            </div>
                           
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class="form-group">Nom: </label>
                    
                        <div class="input-group input-group-lg">

                            <input type="text" class="form-control form-control-solid" id="name" formControlName="name" disabled
                            aria-describedby="basic-addon2">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="row col-lg-12">
                        <button class="btn btn-primary btn-elevate col-lg-2" (click)="addNewItem()">Ajouter une
                            Ligne</button>
                    </div>
                </div>
                <div class="row">
                    <angular-slickgrid
                    class="col-6"
                    
                    gridId="repGrid"
                    [columnDefinitions]="columnDefinitions"
                    (onAngularGridCreated)="angularGridReady($event)"
                    [gridOptions]="gridOptions"
                    [dataset]="reps"
                    >
                    </angular-slickgrid>
               
                    <angular-slickgrid
                    class="col-6"
                    
                    gridId="repGridJob"
                    [columnDefinitions]="columnDefinitionsJob"
                    (onAngularGridCreated)="angularGridReadyJob($event)"
                    [gridOptions]="gridOptionsJob"
                    [dataset]="jobs"
                    >
                    </angular-slickgrid>
                </div>
            </form>
          
        </div>
        <div>
            <ng-template #content let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir Fournisseur </h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="griduser"
                            [columnDefinitions]="columnDefinitionscust" (onAngularGridCreated)="angularGridReadycust($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChangedcust($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptionscust" [dataset]="datacust">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template #contentjob let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choisir Métier </h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="gridjob"
                            [columnDefinitions]="columnDefinitionsj"
                            (onAngularGridCreated)="angularGridReadyj($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChangedj($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptionsj" [dataset]="datajob">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
            <button hidden id="openJobsGrid" class="btn btn-primary" (click)="openjob(contentjob)">Launch demo
                modal</button>
        </div>
    </kt-portlet-body>
</kt-portlet>

