<!--begin::User-->
<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="user$ | async as _user"
  class="dropdown dropdown-fluid"
>
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
        >Hi,</span
      >
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
        >{{ _user.fullname }}</span
      >
      <span class="symbol symbol-35 symbol-light-success">
        <span class="symbol-label font-size-h5 font-weight-bold">{{
          _user.fullname | firstLetter
        }}</span>
      </span>
    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div
    ngbDropdownMenu
    class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0"
  >
    <ng-container *ngIf="userDropdownStyle === 'light'">
      <!--begin: Head -->
      <div class="d-flex align-items-center p-8 rounded-top">
        <!--begin::Symbol-->
        <div class="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
          <!-- Doc: Use the placeholder instead of the image above
              <span class="symbol-label font-size-h5 text-primary">S</span>
             -->
          <span class="symbol-label font-size-h5 text-primary">{{
            _user.fullname | firstLetter
          }}</span>
          <!--<img alt="Pic" [attr.src]="_user.pic"/>-->
          <!--style="background-image: url(./assets/media/misc/bg-1.jpg)"-->
        </div>
        <!--end::Symbol-->

        <!--begin::Text-->
        <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
          {{ _user.fullname }}
        </div>
        <span
          class="label label-light-success label-lg font-weight-bold label-inline"
          >3 messages</span
        >
        <!--end::Text-->
      </div>
      <!--end: Head -->
    </ng-container>

    <ng-container *ngIf="userDropdownStyle === 'dark'">
      <!--begin::Header-->
      <div
        class="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
        style="background-image: url(./assets/media/misc/bg-1.jpg)"
      >
        <div class="d-flex align-items-center mr-2">
          <!--begin::Symbol-->
          <div class="symbol bg-white-o-15 mr-3">
            <span
              class="symbol-label text-success font-weight-bold font-size-h4"
              >{{ _user.fullname | firstLetter }}</span
            >
            <!--<img alt="Pic" src="<?php echo Page::getMediaPath();?>users/300_25.jpg"/>-->
          </div>
          <!--end::Symbol-->

          <!--begin::Text-->
          <div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">
            {{ _user.fullname }}
          </div>
          <!--end::Text-->
        </div>
        <span class="label label-success label-lg font-weight-bold label-inline"
          >3 messages</span
        >
      </div>
      <!--end::Header-->
    </ng-container>
    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 pt-5">
      <!--begin::Item-->
      <a
        ngbDropdownItem
        routerLink="profile"
        href="javascript:;"
        class="navi-item px-8"
      >
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="flaticon2-calendar-3 text-success"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">My Profile</div>
            <div class="text-muted">
              Account settings and more
              <span
                class="label label-light-danger label-inline font-weight-bold"
                >update</span
              >
            </div>
          </div>
        </div>
      </a>
      <!--end::Item-->

      <!--begin::Item-->
      <a
        ngbDropdownItem
        routerLink="profile"
        href="javascript:;"
        class="navi-item px-8"
      >
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="flaticon2-rocket-1 text-danger"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">My Activities</div>
            <div class="text-muted">Logs and notifications</div>
          </div>
        </div>
      </a>
      <!--end::Item-->

      <a
        ngbDropdownItem
        routerLink="profile"
        href="javascript:;"
        class="navi-item px-8"
      >
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="flaticon2-hourglass text-primary"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">My Tasks</div>
            <div class="text-muted">latest tasks and projects</div>
          </div>
        </div>
      </a>
      <!--end::Item-->

      <!--begin::Footer-->
      <div class="navi-separator mt-3"></div>
      <div class="navi-footer px-8 py-5">
        <a
          href="javascript:;"
          (click)="logout()"
          class="btn btn-light-primary font-weight-bold"
          >Sign Out</a
        >
        <a href="javascript:;" class="btn btn-clean font-weight-bold"
          >Upgrade Plan</a
        >
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Nav-->
  </div>
  <!--end::Dropdown-->
</div>
<!--end::User-->
