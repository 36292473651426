import {BaseModel} from './base.model'

export class Barecodeinfos extends BaseModel{



    id: Number

    item: String 

    description: String 

    
    start: Number

    length: Number 

    
    }