<kt-portlet>
    <kt-portlet-header [sticky]="true" [title]="getComponentTitle()" [class]="'card-head-lg'" [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <button (click)="goBackWithoutId()" type="button" class="btn btn-secondary btn-sm mr-2">Annuller</button>
            <button (click)="reset()" [disabled]="selectedTab !== 0" type="button"
                class="btn btn-secondary btn-sm mr-2">Réinitialiser</button>
            <button (click)="onSubmit()" type="button" class="btn btn-success btn-sm mr-2">sauvegarder </button>



        </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
        <div class="example-preview">
            <div>
                <ngb-tabset>
                    <ngb-tab>
                        <ng-template ngbTabTitle>
                            Information basique
                            <div style="width: 10px;"></div>
                            <a *ngIf="hasFormErrors"
                                class="btn btn-sm btn-icon btn-light-danger pulse pulse-danger mr-5">
                                <i class="flaticon2-information"></i>
                                <span class="pulse-ring"></span>
                            </a>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form [formGroup]="addressForm" class="form">

                                <kt-alert *ngIf="isExist" type="warn" [showCloseButton]="true" [duration]="10000"
                                    (close)="onAlertClose($event)">
                                    Ce Code existe deja
                                </kt-alert>

                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Fournisseur:</label>
                                        <input type="text" (change)="onChangeCode()"
                                            [className]="addressForm.controls['ad_addr'].errors && (addressForm.controls['ad_addr'].dirty || addressForm.controls['ad_addr'].touched) ? 'form-control form-control-sm form-control-solid is-invalid' : 'form-control form-control-sm form-control-solid'"
                                            formControlName="ad_addr">
                                        <div *ngIf="addressForm.controls['ad_addr'].errors && (addressForm.controls['ad_addr'].dirty || addressForm.controls['ad_addr'].touched)"
                                            class="invalid-feedback">Ce Champs est obligatoire</div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Temporaire:</label>
                                        <span class="switch switch-icon">
                                            <label>
                                                <input type="checkbox"
                                                    class="form-control form-control-sm form-control-solid"
                                                    name="select" formControlName="ad_temp" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label class="form-group ">Nom:</label>
                                            <input type="text"
                                                [className]="addressForm.controls['ad_name'].errors && (addressForm.controls['ad_name'].dirty || addressForm.controls['ad_name'].touched) ? 'form-control form-control-sm form-control-solid is-invalid' : 'form-control form-control-sm form-control-solid'"
                                                formControlName="ad_name">
                                            <div *ngIf="addressForm.controls['ad_name'].errors && (addressForm.controls['ad_name'].dirty || addressForm.controls['ad_name'].touched)"
                                                class="invalid-feedback">Ce Champs est obligatoire</div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="form-group">Addresse:</label>
                                        
                                            <input type="text"
                                                [className]="addressForm.controls['ad_line1'].errors && (addressForm.controls['ad_line1'].dirty || addressForm.controls['ad_line1'].touched) ? 'form-control form-control-sm form-control-solid is-invalid' : 'form-control form-control-sm form-control-solid'"
                                                formControlName="ad_line1">
                                            <div *ngIf="addressForm.controls['ad_line1'].errors && (addressForm.controls['ad_line1'].dirty || addressForm.controls['ad_line1'].touched)"
                                                class="invalid-feedback">Ce Champs est obligatoire</div>
                                        
                                    </div>
                                   

                                </div>

                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Pays:</label>
                                        <select class="form-control form-control-sm form-control-solid"
                                        id="exampleSelects1" formControlName="ad_country">
                                        <option value=""></option>
                                        <option *ngFor="let elem of ad_country" [value]="elem.code_value">
                                            {{elem.code_value}} {{elem.code_cmmt}}</option>
                                           

                                    </select>
                                    </div>
                               
                                    
                                    <div class="form-group col-2">
                                        <label class="form-group ">Wilaya:</label>
                                            <select class="form-control form-control-sm form-control-solid"
                                                id="exampleSelects2" formControlName="ad_state" (change)="onChangeState()">
                                                <option value=""></option>
                                                <option *ngFor="let elem of ad_state" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Commune:</label>
                                            <select class="form-control form-control-sm form-control-solid"
                                                id="exampleSelects1" formControlName="ad_city">
                                                <option value=""></option>
                                                <option *ngFor="let elem of ad_city" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Post:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_zip">
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Contact:</label>
                                       
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_attn">
                                    </div>
                                    


                                    <div class="form-group col-2">
                                        <label class="form-group ">Telephone:</label>
                                        
                                            <input type="text"
                                                [className]="addressForm.controls['ad_phone'].errors && (addressForm.controls['ad_phone'].dirty || addressForm.controls['ad_phone'].touched) ? 'form-control form-control-sm form-control-solid is-invalid' : 'form-control form-control-sm form-control-solid'"
                                                formControlName="ad_phone">
                                            <div *ngIf="addressForm.controls['ad_phone'].errors && (addressForm.controls['ad_phone'].dirty || addressForm.controls['ad_phone'].touched)"
                                                class="invalid-feedback">Ce Champs est obligatoire</div>
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Pst:</label>
                                    
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_ext">
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Fax:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_fax">
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Contact 2:</label>
                                       
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_attn2">
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Tel 2:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_phone2">
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Pst:</label>
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_ext2">
                                    </div>

                                    
                                    <div class="form-group col-2">
                                        <label class="form-group ">Fax 2</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid" formControlName="ad_fax2">
                                        
                                    </div>


                                </div>

                            </form>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle>
                            Données fournisseur
                            <div style="width: 10px;"></div>
                            <a *ngIf="hasProviderFormErrors"
                                class="btn btn-sm btn-icon btn-light-danger pulse pulse-danger mr-5">
                                <i class="flaticon2-information"></i>
                                <span class="pulse-ring"></span>
                            </a>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form [formGroup]="providerForm" class="form">

                                <kt-alert *ngIf="hasProviderFormErrors" type="warn" [showCloseButton]="true"
                                    [duration]="10000" (close)="onAlertClose($event)">
                                    Modifiez quelques éléments et réessayez de soumettre.
                                </kt-alert>

                                <div class="row">
                                    <div class="form-group col-1">
                                        <label class="form-group">SEQUENCE:</label>
                                        <div class="input-group input-group-sm">
                
                                            <input type="text"   id="SEQUENCE"
                                            class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_seq" (change)="onChangeSeq()" aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)=open1(content1)>
                                                    <i class="text-dark-50 flaticon2-search"></i> </span>
                                            </div>
                
                                        </div>
                
                
                                    </div>
                                    <div class="form-group col-4">
                                        <label class="form-group">Nom Tri:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_sort">
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Type:</label>
                                       
                                            <select class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_type" id="exampleSelects4">
                                                <option value=""></option>
                                                <option *ngFor="let elem of vd_type" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Compte Ach:</label>
                                       
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid" (change)="changeAcct('vd_act_acct')"
                                                    aria-describedby="basic-addon2" formControlName="vd_act_acct">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="open3(content3,'vd_act_acct')">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Sous Compte</label>
                                     
                                           <div class="input-group input-group-sm">
                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_act_sub">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-2 ">
                                        <label class="form-group">centre de cout</label>
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_act_cc">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Compte CF:</label>
                                        
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid" (change)="changeAcct('vd_ap_acct')"
                                                    aria-describedby="basic-addon2" formControlName="vd_ap_acct">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="open3(content3,'vd_ap_acct')">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Sous Compte:</label>
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_ap_sub">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Centre de Cout:</label>
                                        <div class="input-group input-group-sm">

                                            <input type="text" class="form-control form-control-solid"
                                                aria-describedby="basic-addon2" formControlName="vd_ap_cc">
                                            <div class="input-group-append">
                                                <span class="input-group-text">
                                                    <i class="text-dark-50 flaticon2-search"></i> </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Mode exp:</label>
                                        
                                            <select class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_shipvia" id="exampleSelects5">
                                                <option value=""></option>
                                                <option *ngFor="let elem of vd_shipvia" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group "> Banque:</label>
                                        <div class="input-group input-group-sm">

                                            <input type="text" class="form-control form-control-solid"
                                                formControlName="vd_bank" aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="openbank(contentbank)">
                                                    <i class="text-dark-50 flaticon2-search"></i> </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">    
                                    <div class="form-group col-2">
                                        <label class="form-group "> Check Form:</label>
                                        
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_ckfrm">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="open4(content4,'vd_ckfrm')">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group "> Devise:</label>
                                        
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_curr">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="open2(content2)">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group "> Langue:</label>
                                            <select class="form-control form-control-sm form-control-solid"
                                                id="exampleSelects6" formControlName="vd_lang">
                                                <option value=""></option>
                                                <option *ngFor="let elem of vd_lang" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Contact d'achat</label>
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    formControlName="vd_pur_cntct" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Contact CF:</label>
                                             <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    formControlName="vd_ap_cntct" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Crediteur divers:</label>
                                        
                                        <span class="switch switch-icon">
                                            <label>
                                                <input type="checkbox" formControlName="vd_misc_cr"
                                                    class="form-control form-control-sm form-control-solid"
                                                    name="select" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group ">Transport:</label>
                                        
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    formControlName="vd_carrier_id" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    
                                    </div>
                               
                                    <div class="form-group col-2">
                                        <label class="form-group ">Groupe promotion</label>
                                        
                                            <select class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_promo" id="exampleSelects8">
                                                <option value=""></option>
                                                <option *ngFor="let elem of vd_promo" [value]="elem.code_value">
                                                    {{elem.code_value}} {{elem.code_cmmt}}</option>

                                            </select>
                                      
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Fournisseur kanban:</label>
                                        
                                            <span class="switch switch-icon">
                                                <label>
                                                    <input type="checkbox"
                                                        class="form-control form-control-sm form-control-solid"
                                                        formControlName="vd_kanban_supplier" name="select" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab>
                        <ng-template ngbTabTitle>
                            Données Taxe ADR
                            <div style="width: 10px;"></div>
                            <a *ngIf="hasFormErrors"
                                class="btn btn-sm btn-icon btn-light-danger pulse pulse-danger mr-5">
                                <i class="flaticon2-information"></i>
                                <span class="pulse-ring"></span>
                            </a>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form [formGroup]="addressForm" class="form">

                                <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                                    (close)="onAlertClose($event)">
                                    Modifiez quelques éléments et réessayez de soumettre.
                                </kt-alert>

                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group">A taxer:</label>
                                        
                                            <span class="switch switch-icon">
                                                <label>
                                                    <input type="checkbox"
                                                        class="form-control form-control-sm form-control-solid"
                                                        name="select" formControlName="ad_taxable" />
                                                    <span></span>
                                                </label>
                                            </span>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Zone taxe:</label>
                                        <select class="form-control form-control-sm form-control-solid"
                                            id="exampleSelects2" formControlName="ad_tax_zone">
                                            <option value=""></option>
                                            <option *ngFor="let elem of ad_tax_zone" [value]="elem.code_value">
                                                {{elem.code_value}} {{elem.code_cmmt}}</option>

                                        </select>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Taux tax:</label>
                                        <div class="input-group input-group-sm">

                                            <input type="text" class="form-control form-control-solid"
                                                aria-describedby="basic-addon2" formControlName="ad_taxc">
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)=opentax(contenttax)>
                                                    <i class="text-dark-50 flaticon2-search"></i> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Emploi tax:</label>
                                        <select class="form-control form-control-sm form-control-solid"
                                            id="exampleSelects2" formControlName="ad_tax_usage">
                                            <option value=""></option>
                                            <option *ngFor="let elem of ad_tax_usage" [value]="elem.code_value">
                                                {{elem.code_value}} {{elem.code_cmmt}}</option>

                                        </select>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Tax comprise:</label>
                                        <span class="switch switch-icon">
                                            <label>
                                                <input type="checkbox"
                                                    class="form-control form-control-sm form-control-solid"
                                                    name="select" formControlName="ad_tax_in" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    
                                    <div class="form-group col-3">
                                        <label class="form-group ">Marticule Fiscale:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_misc2_id">
                                    </div>
                                    <div class="form-group col-3">
                                        <label class="form-group ">RC N:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_gst_id">
                                    </div>
                                    <div class="form-group col-3">
                                        <label class="form-group ">AI N:</label>
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_pst_id">
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-3">
                                        <label class="form-group ">NIS N:</label>
                                        
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="ad_misc1_id">
                                        
                                    </div>
                                    
                                    

                                </div>
                            </form>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab>
                        <ng-template ngbTabTitle>
                            Données paiement Fournisseur
                            <div style="width: 10px;"></div>
                            <a *ngIf="hasFormErrors"
                                class="btn btn-sm btn-icon btn-light-danger pulse pulse-danger mr-5">
                                <i class="flaticon2-information"></i>
                                <span class="pulse-ring"></span>
                            </a>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form [formGroup]="providerForm" class="form">

                                <kt-alert *ngIf="hasProviderFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                                    (close)="onAlertClose($event)">
                                    Modifiez quelques éléments et réessayez de soumettre.
                                </kt-alert>

                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group "> Conditions:</label>
                                        
                                            <div class="input-group input-group-sm">

                                                <input type="text" class="form-control form-control-solid"
                                                    aria-describedby="basic-addon2" formControlName="vd_cr_terms">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="open4(content4,'vd_cr_terms')">
                                                        <i class="text-dark-50 flaticon2-search"></i> </span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="row">    
                                    <div class="form-group col-2">
                                        <label class="form-group ">% remise:</label>
                                        <input type="number" class="form-control form-control-sm form-control-solid"
                                            formControlName="vd_disc_pct">
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group ">Solde acompte:</label>
                                            <input type="number" class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_prepay">
                                    </div>
                                    
                                    <div class="form-group col-2">
                                        <label class="form-group ">Numero dibiteur:</label>
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_debtor">
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="form-group col-2">
                                        <label class="form-group">OK partiel:</label>
                                            <span class="switch switch-icon">
                                                <label>
                                                    <input type="checkbox"
                                                        class="form-control form-control-sm form-control-solid"
                                                        name="select" formControlName="vd_partial" />
                                                    <span></span>
                                                </label>
                                            </span>
                                    </div>
                                    
                                </div>
                                <div class="row">    
                                    <div class="form-group col-2">
                                        <label class="form-group">Blocage:</label>
                                            <span class="switch switch-icon">
                                                <label>
                                                    <input type="checkbox"
                                                        class="form-control form-control-sm form-control-solid"
                                                        name="select" formControlName="vd_hold" />
                                                    <span></span>
                                                </label>
                                            </span>
                                    </div>
                                    <div class="form-group col-2">
                                        <label class="form-group">Specification paiement:</label>
                                            <span class="switch switch-icon">
                                                <label>
                                                    <input type="checkbox"
                                                        class="form-control form-control-sm form-control-solid"
                                                        name="select" formControlName="vd_pay_spec" />
                                                    <span></span>
                                                </label>
                                            </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label class="form-group ">N base:</label>
                                            <input type="text" class="form-control form-control-sm form-control-solid"
                                                formControlName="vd_db">
                                    </div>
                                </div>

                            </form>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>

        <div>
            <ng-template #content3 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir Compte </h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="griduser"
                            [columnDefinitions]="columnDefinitions3" (onAngularGridCreated)="angularGridReady3($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChanged3($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptions3" [dataset]="data">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template #contenttax let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir </h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="griduser"
                            [columnDefinitions]="columnDefinitionstax" (onAngularGridCreated)="angularGridReadytax($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChangedtax($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptionstax" [dataset]="datatax">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template #content4 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir </h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="griduser"
                            [columnDefinitions]="columnDefinitions4" (onAngularGridCreated)="angularGridReady4($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChanged4($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptions4" [dataset]="datacode">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>

        <div>
            <ng-template #contentbank let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir la banque</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="gridpro"
                            [columnDefinitions]="columnDefinitionsbank" (onAngularGridCreated)="angularGridReadybank($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChangedbank($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptionsbank" [dataset]="banks">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template #content2 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir la Devise</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="gridpro"
                            [columnDefinitions]="columnDefinitions2" (onAngularGridCreated)="angularGridReady2($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChanged2($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptions2" [dataset]="devises">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template #content1 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h4 class="modal-title">Choissir la SEQUENCE</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <angular-slickgrid class="col-12" gridHeight="300" gridId="gridSeq"
                            [columnDefinitions]="columnDefinitions1" (onAngularGridCreated)="angularGridReady($event)"
                            (sgOnSelectedRowsChanged)="handleSelectedRowsChanged($event.detail.eventData, $event.detail.args)"
                            [gridOptions]="gridOptions1" [dataset]="sequences">
                        </angular-slickgrid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Annuller</button>
                    <button type="button" class="btn btn-primary" (click)="c('Close click')">Ok</button>
                </div>
            </ng-template>
        </div>


    </kt-portlet-body>
</kt-portlet>