
import { BaseModel } from "./base.model"

export class InvoiceOrder extends BaseModel {

    id: Number
    ih_nbr: String
    ih_cust: String
    ih_ship: String
    ih_category: String
    ih_ord_date: String
    ih_req_date: String
    ih_due_date: String
    ih_rmks: String
    ih_cr_terms: String
    ih_fob: String
    ih_po: String
    ih_shipvia: String
    ih_partial: Boolean
    ih_print_so: Boolean
    ih_inv_nbr: String
    ih_pr_list: String
    ih_xslspsn: String
    ih_source: String
    ih_xcomm_pct: Number
    ih_cr_card: String
    ih_print_pl: Boolean
    ih_cr_init: String
    ih_stat: String
    ih__qad01: Number
    ih__qad02: Number
    ih__qad03: Number
    ih_disc_pct: Number
    ih_tax_pct: Number
    ih_prepaid: Number
    ih_to_inv: Boolean
    ih_invoiced: Boolean
    ih_ar_acct: String
    ih_ar_cc: String
    ih_inv_date: String
    ih_ship_date: String
    ih_taxable: Boolean
    ih_cmtindx: Number
    ih__qad04: Number
    ih_user1: String
    ih_user2: String
    ih_curr: String
    ih_ex_rate: Number
    ih_lang: String
    ih_type: String
    ih_conf_date: String
    ih_rev: Number
    ih_bol: String
    ih__qad05: Number
    ih_pst: Boolean
    ih_fst_id: String
    ih_amt: Number
    ih_tot_amt: Number
    ih_disc_glb: Number
    ih_transport: Number
    ih_tax_amt: Number
    ih_trl1_amt: Number
    ih_trl1_cd: String
    ih_trl2_amt: Number
    ih_trl2_cd: String
    ih_trl3_amt: Number
    ih_trl3_cd: String
    ih_weight: Number
    ih_weight_um: String
    ih_size: Number
    ih_size_um: String
    ih_cartons: Number
    ih_site: String
    ih_pst_id: String
    ih_cncl_date: String
    ih_quote: String
    ih_taxc: String
    ih__chr01: String
    ih__chr02: String
    ih__chr03: String
    ih__chr04: String
    ih__chr05: String
    ih__chr06: String
    ih__chr07: String
    ih__chr08: String
    ih__chr09: String
    ih__chr10: String
    ih__dte01: String
    ih__dte02: String
    ih__dec01: Number
    ih__dec02: Number
    ih__log01: Boolean
    ih_credit: Boolean
    ih_inv_cr: String
    ih_project: String
    ih_channel: String
    ih_pst_pct: Number
    ih_fr_list: String
    ih_fr_terms: String
    ih_slspsn: String
    ih_comm_pct: Number
    ih_inv_mthd: String
    ih_fix_rate: Boolean
    ih_ent_ex: Number
    ih_bill: String
    ih_print_bl: Boolean
    ih_userid: String
    ih_tax_date: String
    ih_fsm_type: String
    ih_conrep: String
    ih_bank: String
    ih_tax_env: String
    ih_sched: Boolean
    ih_fr_min_wt: Number
    ih_pr_list2: String
    ih_tax_usage: String
    ih_sa_nbr: String
    ih_fix_pr: Boolean
    ih_sch_mthd: String
    ih_pricing_dt: String
    ih_priced_dt: String
    ih_ca_nbr: String
    ih_eng_code: String
    ih_fcg_code: String
    ih_ship_eng: Boolean
    ih_crprlist: String
    ih__qadc01: String
    ih__qadc02: String
    ih__qadc03: String
    ih__qadc04: String
    ih__qadc05: String
    ih__qadl01: Boolean
    ih__qadl02: Boolean
    ih_incl_iss: Boolean
    ih__qadi01: Number
    ih__qadi02: Number
    ih__qadi03: Number
    ih__qadd01: Number
    ih__qadd02: Number
    ih__qadd03: Number
    ih__qadt01: String
    ih__qadt02: String
    ih__qadt03: String
    ih_auth_days: Number
    ih_cum_acct: Boolean
    ih_merge_rss: Boolean
    ih_ship_cmplt: Number
    ih_bump_all: Boolean
    ih_primary: Boolean
    ih_cust_po: String
    ih_secondary: Boolean
    ih_ship_po: String
    ih_ex_rate2: Number
    ih_ex_ratetype: String
    ih_div: String
    ih_exru_seq: Number
    ih_app_owner: String
    ih_ar_sub: String
    ih_seq_order: Boolean
    ih_inc_in_rss: Boolean
    ih_firm_seq_days: Number
    ih_prep_tax: Number
    ih__qadl04: Boolean
    ih_custref_val: Boolean
    ih_consignment: Boolean
    ih_max_aging_days: Number
    ih_consign_loc: String
    ih_intrans_loc: String
    ih_auto_replenish: Boolean
    ih_revenue: String
    ih_fsaccr_acct: String
    ih_fsaccr_sub: String
    ih_fsaccr_cc: String
    ih_fsdef_acct: String
    ih_fsdef_sub: String
    ih_fsdef_cc: String
    ih_manual_fr_terms: Boolean
    ih_domain: String
    oid_ih_mstr: Number
    ih_req_time: String
    ith_disc_glb: Number;
    ith_transport: Number;
    ith_rt_gara: Number;
}
