
import { BaseModel } from "./base.model"

export class VoucherOrder extends BaseModel {

    id: Number
    vh_nbr: String
    vh_ship: String
    vh_entity: String
    vh_category: String
    vh_ord_date: String
    vh_req_date: String
    vh_due_date: String
    vh_rmks: String
    vh_cr_terms: String
    vh_fob: String
    vh_po: String
    vh_shipvia: String
    vh_partial: Boolean
    vh_print_so: Boolean
    vh_inv_nbr: String
    vh_pr_list: String
    vh_xslspsn: String
    vh_source: String
    vh_xcomm_pct: Number
    vh_cr_card: String
    vh_print_pl: Boolean
    vh_cr_init: String
    vh_stat: String
    vh__qad01: Number
    vh__qad02: Number
    vh__qad03: Number
    vh_disc_pct: Number
    vh_tax_pct: Number
    vh_prepaid: Number
    vh_to_inv: Boolean
    vh_invoiced: Boolean
    vh_ar_acct: String
    vh_ar_cc: String
    vh_inv_date: String
    vh_ship_date: String
    vh_taxable: Boolean
    vh_cmtindx: Number
    vh__qad04: Number
    vh_user1: String
    vh_user2: String
    vh_curr: String
    vh_ex_rate: Number
    vh_lang: String
    vh_type: String
    vh_conf_date: String
    vh_rev: Number
    vh_bol: String
    vh__qad05: Number
    vh_pst: Boolean
    vh_fst_id: String
    vh_amt: Number
    vh_tax_amt: Number
    vh_trl1_amt: Number
    vh_trl1_cd: String
    vh_trl2_amt: Number
    vh_trl2_cd: String
    vh_trl3_amt: Number
    vh_trl3_cd: String
    vh_weight: Number
    vh_weight_um: String
    vh_size: Number
    vh_size_um: String
    vh_cartons: Number
    vh_site: String
    vh_pst_id: String
    vh_cncl_date: String
    vh_quote: String
    vh_taxc: String
    vh__chr01: String
    vh__chr02: String
    vh__chr03: String
    vh__chr04: String
    vh__chr05: String
    vh__chr06: String
    vh__chr07: String
    vh__chr08: String
    vh__chr09: String
    vh__chr10: String
    vh__dte01: String
    vh__dte02: String
    vh__dec01: Number
    vh__dec02: Number
    vh__log01: Boolean
    vh_credit: Boolean
    vh_inv_cr: String
    vh_project: String
    vh_channel: String
    vh_pst_pct: Number
    vh_fr_list: String
    vh_fr_terms: String
    vh_slspsn: String
    vh_comm_pct: Number
    vh_inv_mthd: String
    vh_fix_rate: Boolean
    vh_ent_ex: Number
    vh_vend: String
    vh_print_bl: Boolean
    vh_userid: String
    vh_tax_date: String
    vh_fsm_type: String
    vh_conrep: String
    vh_bank: String
    vh_tax_env: String
    vh_sched: Boolean
    vh_fr_min_wt: Number
    vh_pr_list2: String
    vh_tax_usage: String
    vh_sa_nbr: String
    vh_fix_pr: Boolean
    vh_sch_mthd: String
    vh_pricing_dt: String
    vh_priced_dt: String
    vh_ca_nbr: String
    vh_eng_code: String
    vh_fcg_code: String
    vh_ship_eng: Boolean
    vh_crprlist: String
    vh__qadc01: String
    vh__qadc02: String
    vh__qadc03: String
    vh__qadc04: String
    vh__qadc05: String
    vh__qadl01: Boolean
    vh__qadl02: Boolean
    vh_incl_iss: Boolean
    vh__qadi01: Number
    vh__qadi02: Number
    vh__qadi03: Number
    vh__qadd01: Number
    vh__qadd02: Number
    vh__qadd03: Number
    vh__qadt01: String
    vh__qadt02: String
    vh__qadt03: String
    vh_auth_days: Number
    vh_cum_acct: Boolean
    vh_merge_rss: Boolean
    vh_ship_cmplt: Number
    vh_bump_all: Boolean
    vh_primary: Boolean
    vh_cust_po: String
    vh_secondary: Boolean
    vh_ship_po: String
    vh_ex_rate2: Number
    vh_ex_ratetype: String
    vh_div: String
    vh_exru_seq: Number
    vh_app_owner: String
    vh_ar_sub: String
    vh_seq_order: Boolean
    vh_inc_in_rss: Boolean
    vh_firm_seq_days: Number
    vh_prep_tax: Number
    vh__qadl04: Boolean
    vh_custref_val: Boolean
    vh_consignment: Boolean
    vh_max_aging_days: Number
    vh_consign_loc: String
    vh_intrans_loc: String
    vh_auto_replenish: Boolean
    vh_revenue: String
    vh_fsaccr_acct: String
    vh_fsaccr_sub: String
    vh_fsaccr_cc: String
    vh_fsdef_acct: String
    vh_fsdef_sub: String
    vh_fsdef_cc: String
    vh_manual_fr_terms: Boolean
    vh_domain: String
    oid_vh_mstr: Number
    vh_req_time: String
}
