<kt-portlet>
	<kt-portlet-header [sticky]="true" [title]="getComponentTitle()" 
		[class]="'card-head-lg'" [viewLoading$]="loading$">
		<ng-container ktPortletTools>
			<a (click)="goBackWithoutId()" class="btn btn-secondary mr-2"  mat-raised-button matTooltip="Back to the products list">
				<i class="la la-arrow-left"></i>
				<span class="hidden-mobile">Back</span>
			</a>
			<a href="javascript:;" class="btn btn-secondary mr-2" (click)="reset()" [disabled]="selectedTab !== 0" mat-raised-button matTooltip="Reset changes">
				<i class="la la-cog"></i>
				<span class="hidden-mobile">Reset</span>
			</a>
			<a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="onSumbit(false)" mat-raised-button matTooltip="Save & Continue">
				<span class="hidden-mobile">Save</span>
			</a>
			<button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item color="primary" (click)="onSumbit(true)">Save & Exit</button>
				<button mat-menu-item color="primary">Save & Duplicate</button>
				<button mat-menu-item color="primary" (click)="onSumbit(false)">Save & Continue</button>
			</mat-menu>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body>
		<mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="product">
			<mat-tab label="Basic info">
				<ng-template matTabContent>
					<div *ngIf="product">
						<!--begin::Form-->
						<form [formGroup]="productForm" class="form form-group-seperator-dashed">

							<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
								Modifiez quelques éléments et réessayez de soumettre.
							</kt-alert>

							<div class="form-section form-section-first">
								<div class="form-group">
									<div class="row">

										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter Model" formControlName="model">
												<mat-error>
													Model is
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Model</strong>
												</mat-hint>
											</mat-form-field>
										</div>
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter Manufacture" formControlName="manufacture" [matAutocomplete]="manuf">
												<mat-autocomplete #manuf="matAutocomplete">
													<mat-option *ngFor="let manufacture of filteredManufactures | async" [value]="manufacture">
														{{ manufacture }}
													</mat-option>
												</mat-autocomplete>
												<mat-error>
													Manufacture is
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Manufacture</strong>
												</mat-hint>
											</mat-form-field>
										</div>
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<mat-select placeholder="Enter Model year" formControlName="modelYear">
													<mat-option value="{{year}}" *ngFor="let year of availableYears;">{{year}}</mat-option>
												</mat-select>
												<mat-error>
													Model year
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Model year</strong>
												</mat-hint>
											</mat-form-field>
										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter Mileage" formControlName="mileage">
												<mat-error>
													Mileage is
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Mileage</strong>
												</mat-hint>
											</mat-form-field>
										</div>
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter Color" formControlName="color" [matAutocomplete]="col">
												<mat-autocomplete #col="matAutocomplete">
													<mat-option *ngFor="let col of filteredColors | async" [value]="col">
														{{ col }}
													</mat-option>
												</mat-autocomplete>
												<mat-error>
													Color
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Color</strong>
												</mat-hint>
											</mat-form-field>
										</div>
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter Price" formControlName="price">
												<span matPrefix>$&nbsp;</span>
												<mat-error>
													Price is
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>Price</strong>
												</mat-hint>
											</mat-form-field>
										</div>
									</div>

								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<input matInput placeholder="Enter VIN code" formControlName="VINCode">
												<mat-error>
													VIN Code is
													<strong>required</strong>
												</mat-error>
												<mat-hint align="start">Please enter
													<strong>VIN Code</strong>
												</mat-hint>
											</mat-form-field>
										</div>
										<div class="col-md-4 kt-margin-bottom-10-mobile">
											<div>Condition:</div>
											<mat-radio-group formControlName="condition" class="example-radio-group">
												<mat-radio-button class="example-radio-button" value="0">New</mat-radio-button>
												<mat-radio-button class="example-radio-button" value="1">Used</mat-radio-button>
											</mat-radio-group>
										</div>
										<div class="col-md-4">
											<div>Status:</div>
											<mat-radio-group formControlName="status" class="example-radio-group">
												<mat-radio-button class="example-radio-button" value="0">Selling</mat-radio-button>
												<mat-radio-button class="example-radio-button" value="1">Sold</mat-radio-button>
											</mat-radio-group>
										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-12 kt-margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid">
												<textarea matInput rows="7" placeholder="Enter Description" formControlName="description"></textarea>
											</mat-form-field>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>

					<!--end::Form-->
				</ng-template>
			</mat-tab>
			<mat-tab label="Product remarks" [disabled]="!product || !product.id">
				<ng-template matTabContent>
					<kt-remarks-list [productId$]="productId$"></kt-remarks-list>
				</ng-template>
			</mat-tab>
			<mat-tab label="Product specifications" [disabled]="!product || !product.id">
				<ng-template matTabContent>
					<kt-specifications-list [productId$]="productId$"></kt-specifications-list>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
	</kt-portlet-body>
</kt-portlet>
