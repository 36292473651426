
import {BaseModel} from './base.model'

export class Doctor extends BaseModel{



 id: Number
doc_code: String   
doc_name: String
doc_line1: String 
doc_line2: String
doc_line3: String
doc_country: String
doc_city: String
doc_county: String
doc_state: String
doc_zip: Number
doc_phone: String
doc_fax: String
doc_mail: String
doc_spec: String


}