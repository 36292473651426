import { BaseModel } from "./base.model";

export class Bank extends BaseModel {
  id: Number;

  bk_code: String;
  bk_curr: String;

  bk_acct: String;
  bk_sub: String;

  bk_cc: String;

  bk_check: Number;

  bk_desc: String;

  bk_user1: String;

  bk_user2: String;

  bk_entity: String;

  bk_pip_acct: String;

  bk_pip_sub: String;

  bk_pip_cc: String;

  bk_bk_acct1: String;

  bk_bk_acct2: String;

  bk_userid: String;
  bk_type: String;

  bk_mod_date: String;

  bk_min_drft: Number;

  bk_max_drft: Number;

  bk_drft_chg: Number;

  bk_drft_chg_pct: Number;

  bk_dftar_acct: String;
  bk_dftar_sub: String;

  bk_dftar_cc: String;

  bk_dftap_acct: String;
  bk_dftap_sub: String;

  bk_dftap_cc: String;

  bk_edft_acct: String;
  bk_edft_sub: String;

  bk_edft_cc: String;

  bk_cdft_acct: String;
  bk_cdft_sub: String;

  bk_cdft_cc: String;

  bk_bkchg_acct: String;
  bk_bkchg_sub: String;
  bk_bkchg_cc: String;

  bk_disc_acct: String;
  bk_disc_sub: String;

  bk_disc_cc: String;

  bk_ddft_acct: String;
  bk_ddft_sub: String;
  bk_ddft_cc: String;
  bk_bktx_acct: String;
  bk_bktx_sub: String;
  bk_bktx_cc: String;
  bk_num_doc: Date;
  bk_domain: String;
  bk_balance: Number;
  // bk_2000: Number;
  // bk_1000: Number;
  // bk_0500: Number;
  // bk_0200: Number;
  // bk_p200: Number;
  // bk_p100: Number;
  // bk_p050: Number;
  // bk_p020: Number;
  // bk_p010: Number;
  // bk_p005: Number;
}
